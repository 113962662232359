import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Content from "../components/content";
import Loader from "../components/loader";
import { useImpersonation } from "../global/impersonation";

import { Button, TextField, Box } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";

import Api from "../libs/api";
import Storage from "../libs/storage";
import BasicTitle from "../components/title";
import Helper from "../libs/helper";

const Holder = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
`;

const Title = styled.h3`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
`;

const ModalRejector = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    height: 380px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid rgb(144, 144, 144);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalClose = styled.img`
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;

const ModalPadding = styled.div`
    padding: 20px;
`;

const TextModalExport = styled.h3`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #181122;
    margin-bottom: 16px;
    text-align: center;
`;

const Data = styled.div`
    margin-bottom: 40px;
`;

export default function Impersonate() {
    let api = Api();
    let storage = Storage();
    let helper = Helper();
    let navigate = useNavigate();
    const { startImpersonation } = useImpersonation();

    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState();
    const [rows, setRows] = useState([
        {
            id: 0,
            name: "---",
            user_name: "---",
            email: "---",
            last_login: "---",
        },
    ]);
    const [filter, setFilter] = useState("");

    const columns = [
        { field: "id", headerName: "ID", width: 100 },
        { field: "name", headerName: "CLIENTE", width: 200 },
        { field: "user_name", headerName: "RESPONSÁVEL", width: 200 },
        { field: "email", headerName: "CONTATO", width: 400 },
        { field: "last_login", headerName: "ÚLTIMO LOGIN", width: 200 },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            disableExport: true,
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleImpersonate(params.row.id)}
                    style={{
                        borderRadius: 8,
                    }}
                >
                    IMPERSONAR
                </Button>
            ),
        },
    ];

    const handleImpersonate = (id) => {
        startImpersonation(id);
        navigate("/refunds");
    };

    const allCompanies = async () => {
        setLoading(true);
        const data = await storage.getItem("data");
        if (!data) return;
        let response = await api.allCompanies(data.token);
        response = response.data.allZagviewCompaniesWithOwners.edges;
        let _rows = [];
        for (let index = 0; index < response.length; index++) {
            const company = response[index].node;
            const _company = {
                id: parseInt(atob(company?.id).split(":")[1]),
                name: company?.name,
                user_name: company?.ownerName,
                email: company?.ownerEmail,
                last_login: company?.ownerLastLogin ? helper.dateFromString(company?.ownerLastLogin) : "---",
            };

            let shouldPush = true;
            if (shouldPush) {
                _rows.push(_company);
            }
            setLoading(false);
            setRows(_rows);
            setCompanies(_rows);
        }
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleFilter = () => {
        if (filter === "") {
            setRows(companies);
        } else {
            const filteredRows = companies.filter((company) =>
                company.name.toLowerCase().includes(filter.toLowerCase())
            );
            setRows(filteredRows);
        }
    };

    useEffect(() => {
        allCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleExportCompanies = async (taskType) => {
        const data = await storage.getItem("data");
        await api.sendToQueue(
            data.token,
            taskType,
            null,
            data.profile.name,
            data.profile.email
        );
    };

    const [openModalMessageExport, setOpenModalMessageExport] = useState(false);

    const showModalMessageExport = () => {
        setOpenModalMessageExport(true);
    };

    const closeModalMessageExport = () => {
        setOpenModalMessageExport(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleFilter();
        }
    };

    function toolbarDatagridOptions() {
        return (
            <GridToolbarContainer>
                <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall"
                    type="button"
                    aria-label="Exportar"
                    onClick={() => {
                        handleExportCompanies("companies");
                        showModalMessageExport();
                    }}
                    style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontWeight: 500,
                        fontSize: "0.8125rem",
                        lineHeight: 1.75,
                        letterSpacing: "0.02857em",
                        textTransform: "uppercase",
                        minWidth: 64,
                        padding: "4px 5px",
                        borderRadius: 4,
                        transition:
                            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        color: "#57C4C2",
                    }}
                >
                    <span
                        className="MuiButton-startIcon MuiButton-iconSizeSmall"
                        style={{ width: 18, height: 18 }}
                    >
                        <svg
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="SaveAltIcon"
                            style={{
                                width: 18,
                                height: 18,
                                margin: "0px 6px",
                                float: "left",
                            }}
                        >
                            <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"></path>
                        </svg>
                    </span>
                    Exportar
                </button>
            </GridToolbarContainer>
        );
    }

    return (
        <>
            {loading && <Loader />}
            <Holder>
                <Content title={"Impersonar"}>
                    <Box style={{ marginBottom: 20 }}>
                        <Title>INFORME A EMPRESA DESEJADA</Title>
                        <TextField
                            value={filter}
                            onChange={handleFilterChange}
                            onKeyDown={handleKeyDown}
                            variant="outlined"
                            style={{
                                width: 282,
                                height: 46,
                                borderRadius: 8,
                            }}
                        />
                        <Button
                            style={{
                                marginLeft: 16,
                                fontSize: 12,
                                padding: "10px 10px 10px 10px",
                                marginBottom: 30,
                                height: 57,
                                borderRadius: 8,
                            }}
                            variant="contained"
                            disableelevation="true"
                            color="primary"
                            onClick={handleFilter}
                        >
                            <img
                                src="/img/search.svg"
                                alt="Filtro"
                                style={{ width: 25, height: 25 }}
                            />
                        </Button>
                    </Box>
                    <Data style={{ marginBottom: 100 }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            className={"hover"}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 20 },
                                },
                            }}
                            // onCellClick={(
                            //   params: GridCellParams,
                            //   event: MuiEvent<React.MouseEvent>
                            // ) => {
                            //   event.defaultMuiPrevented = true;
                            // }}
                            slots={{
                                toolbar: toolbarDatagridOptions,
                            }}
                            pageSizeOptions={[20, 50, 100]}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </Data>
                </Content>
            </Holder>
            <Modal
                open={openModalMessageExport}
                onClose={closeModalMessageExport}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalRejector style={{ height: "auto" }}>
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={closeModalMessageExport}
                    />
                    <ModalPadding>
                        <BasicTitle title={"EXPORTAR DADOS"} />
                    </ModalPadding>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <TextModalExport>
                            AGUARDE ENQUANTO PREPARAMOS
                            <br /> SEU ARQUIVO.
                        </TextModalExport>
                        <img
                            src="/img/man.png"
                            alt=""
                            width={240}
                            height={240}
                        />
                        <TextModalExport
                            style={{
                                fontSize: 14,
                                marginTop: 24,
                                marginBottom: 40,
                                fontWeight: 400,
                            }}
                        >
                            Esta operação poderá levar alguns
                            <br /> minutos. Você receberá um e-mail
                            <br /> quando terminarmos de preparar tudo.
                        </TextModalExport>
                        <Button
                            style={{ marginBottom: 20 }}
                            variant="contained"
                            disableelevation="true"
                            color="primary"
                            onClick={closeModalMessageExport}
                        >
                            OK
                        </Button>
                    </div>
                </ModalRejector>
            </Modal>
        </>
    );
}
