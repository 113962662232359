import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import Api from "../libs/api";
import Storage from "../libs/storage";
import Loader from "../components/loader";
import Button from "@mui/material/Button";

const Holder = styled.div`
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 676px;
`;

const Card = styled.div`
  background: #fff;
  padding: 56px;
  text-align: center;
  width: 100%;
  max-width: 488px;
  border-radius: 8px;
`;

const Logo = styled.img`
  width: 42px;
  margin-bottom: 40px;
`;

const Title = styled.div`
  font-size: 28px;
  margin-bottom: 56px;
`;

const Clear = styled.div`
  clear: both;
`;

const Field = styled.div`
  width: 100%;
  max-width: 327px;
  margin: 0 auto;
  margin-bottom: 24px;
  position: relative;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #181122;
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
`;

const Input = styled.input`
  border: 1px solid #181122;
  width: 100%;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #181122;
  padding: 16px;

  &.error {
    border: 1px solid #de6061;
  }
`;

const Eye = styled.img`
  width: 12px;
  height: 12px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
`;

// const Checkbox = styled.div`
//   width: 100%;
//   max-width: 327px;
//   margin: 0 auto;
//   margin-bottom: 24px;
//   position: relative;
//   text-align: left;
// `;

// const CheckboxInput = styled.input`
//   margin-right: 8px;
// `;

// const CheckboxLabel = styled.span`
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 16px;
//   color: #181122;
// `;

const Submit = styled.div`
  width: 100%;
  max-width: 327px;
  margin: 0 auto;
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #de6061;
  width: 100%;
  text-align: left;
`;

export default function Login() {
  // instances
  //   const helper = Helper();
  const api = Api();
  const storage = Storage();
  let navigate = useNavigate();
  const location = useLocation();

  // states
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    async function fetchTokenFromURL() {
      const queryParams = new URLSearchParams(location.search);
      const hash = queryParams.get("hash"); 
      if (hash) {
        const decodedHash = atob(hash);
        const data = JSON.parse(decodedHash);
        await saveHash(data);
      }
    }
    fetchTokenFromURL();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  async function saveHash(data) {
    const save = await storage.saveItem("data", data);
    if (save) {
      navigate("/refunds");
    }
  }

  const login = async () => {
    if (!username) {
      setErrorMessage("Preencha seu e-mail");
    } else {
      if (!password) {
        setErrorMessage("Preencha sua senha");
      } else {
        setLoading(true);
        const response = await api.login(username, password);
        setLoading(false);
        if (response.data.login.oauthLogin) {
          const save = await storage.saveItem(
            "data",
            response.data.login.oauthLogin
          );
          setErrorMessage(null);
          if (save) {
            navigate("/refunds");
          }
        } else if (response.data.login.oauthLoginError?.messages?.includes("Is not admin")) {
          window.location.href = "https://app.zagapp.com.br";
        } else {
          setErrorMessage("Usuário ou senha inválidos");
        }
      }
    }
  };

  function reset() {
    navigate("/reset");
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      login();
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Holder style={{ height: height, width: width }}>
        <Card>
          <Logo src="/img/logo.png" />
          <Clear />
          <Title>
            <strong>Bem-vindo</strong> ao Zag
          </Title>
          <Field>
            <Label>E-MAIL</Label>
            <Input
              type="text"
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              autoFocus
            />
          </Field>
          <Field>
            <Label>SENHA</Label>
            <Input
              type={showPassword ? "text" : "password"}
              style={{ paddingRight: 50 }}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              onKeyDown={handleKeyDown}
            />
            <Eye
              src={showPassword ? "/img/eye_on.png" : "/img/eye_off.png"}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
          </Field>
          {errorMessage && (
            <Field>
              <Error>{errorMessage}</Error>
            </Field>
          )}
          {/* <Checkbox>
            <CheckboxInput type="checkbox" />
            <CheckboxLabel>Salvar meu login</CheckboxLabel>
          </Checkbox> */}
          <Submit>
            {/* <ButtonSend>ENTRAR</ButtonSend> */}
            <Button
              style={{
                width: "100%",
                maxWidth: 327,
                fontWeight: 700,
                fontSize: 12,
                padding: "12px 0px",
              }}
              variant="contained"
              disableelevation="true"
              color="primary"
              onClick={login}
            >
              ENTRAR
            </Button>
            <Button
              style={{
                width: "100%",
                maxWidth: 327,
                fontWeight: 700,
                fontSize: 12,
                padding: "12px 0px",
                marginTop: 12,
                color: "#000",
              }}
              color="secondary"
              onClick={reset}
            >
              ESQUECI MINHA SENHA
            </Button>
          </Submit>
        </Card>
      </Holder>
    </>
  );
}
