import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Collapse, IconButton } from "@mui/material";
import BasicTitle from "../components/title";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";


import Api from "../libs/api";
import Storage from "../libs/storage";

import Loader from "./loader";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const ModalCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid rgb(144, 144, 144);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalClose = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const ModalView = styled.div`
  margin-bottom: 16px;
`;

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
`;

const Title = styled.h3`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
`;


const Clear = styled.div`
  clear: both;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 20px;
`;

const SubTitle = styled.div`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #181122;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 5px;
`;

const ImageIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
  color: #57c4c2;
`;

const View = styled.div`
  display: flex;
  flex-direction: row;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  width: 25%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ErrorMessage = styled.p`
  color: red;
  padding-left: 15px;
  padding-bottom: 15px;
  font-size: 13px;
`;

const CheckBox = styled.input`
  width: 18px;
  height: 18px;
  margin-right: 15px;
`;

export default function AddCategory({ open, close, profile, impersonate }) {
  let api = Api();
  let storage = Storage();

  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [updateUsers, setUpdateUsers] = useState([]);
  const [name, setName] = useState("");
  const [limitValue, setLimitValue] = useState(0);
  const [company, setCompany] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [isOpen3, setIsOpen3] = useState(true);
  const [checkedState, setCheckedState] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [search, setSearch] = useState("");
  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [applySearch, setApplySearch] = useState(false);
  const [removeAll, setRemoveAll] = useState(false);
  
  const getUsers = async (searchParam = search) => {
      if (loadingUsers) return;
  
      setLoadingUsers(true);
  
      const data = await storage.getItem("data");
      const response = await api.getUsers(
          data.token,
          searchParam ? searchParam : null,
          endCursor,
          impersonate
      );

      let newUsers = response.data.allZagviewUsers.edges
        .map((item) => {
          const userExistsInUpdate = updateUsers.find(u => u.id === item.node.id);
          
          return {
              id: item.node.id,
              name: item.node.name,
              is_restricted: userExistsInUpdate ? userExistsInUpdate.is_restricted : removeAll,
              restrictedCategories: item.node.hiddenCategories,
              disabled: item.node.disabled
          };
      }).filter(user => user.disabled === false);

      newUsers = newUsers.map((user) => {
        const updatedUser = updateUsers.find((u) => u.id === user.id);
        return updatedUser ? updatedUser : user;
      });

      setUsers((prev) => {
        const allUsers = [...prev, ...newUsers];
    
        const uniqueUsers = allUsers.filter(
            (user, index, self) => self.findIndex(u => u.id === user.id) === index
        );
    
        return uniqueUsers;
      });

      setEndCursor(response?.data?.allZagviewUsers?.pageInfo?.endCursor);
      setHasNextPage(response?.data?.allZagviewUsers?.pageInfo?.hasNextPage);
      setLoadingUsers(false);
  };
  
  useEffect(() => {
      if(open) {
        getUsers();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSearch = async () => {
    setApplySearch(true);

    setUsers([]);
    setEndCursor(null);
    setHasNextPage(true);

    await setSearch(search);
    getUsers();
  };

  const handleClearSearch = async () => {
    setApplySearch(true);
    setSearch("");
    setUsers([]);
    setEndCursor(null);
    setHasNextPage(true);
    
    await getUsers(null); 
    
    setApplySearch(false);
};

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };
  
  useEffect(() => {
      if (applySearch) {
        setUsers([]);
        setEndCursor(null);
        setHasNextPage(true);
        getUsers();
        setApplySearch(false);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applySearch]);

  const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        !loading && 
        hasNextPage
      ) {
          getUsers();
      }
  };
  
  const toggleDropdown1 = () => {
      setIsOpen1(!isOpen1);
  };
  
  const toggleDropdown2 = () => {
      setIsOpen2(!isOpen2);
  };
  
  const toggleDropdown3 = () => {
      setIsOpen3(!isOpen3);
  };

  useEffect(() => {
    setUsers((prevUsers) => 
        prevUsers.map((user) => {
            const isRestricted = updateUsers.some((u) => u.id === user.id);
            return { ...user, is_restricted: isRestricted };
        })
    );
  }, [updateUsers]);
  
  useEffect(() => {
    setCheckedState(users.map((user) => !user.is_restricted));
  }, [users]);
  
  useEffect(() => {
      const someUserRestricted = users.some((user) => user.is_restricted === true);
      setSelectAllChecked(!someUserRestricted);
  }, [users]);
  
  const handleCheckboxChange = (position) => {
      if (removeAll) {
          setRemoveAll(false);
      }
  
      const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
  
      const updatedUsers = users.map((user, index) => {
          if (index === position) {
              return { ...user, is_restricted: !user.is_restricted };
          }
          return user;
      });
  
      const updatedUser = updatedUsers[position];
      const userIndex = updateUsers.findIndex((item) => item.id === updatedUser.id);
  
      if (userIndex !== -1) {
          const updatedItems = [...updateUsers];
          updatedItems.splice(userIndex, 1);
          setUpdateUsers(updatedItems);
      } else {
          setUpdateUsers([...updateUsers, updatedUser]);
      }
  };
  
  const selectedAll = () => {
      setRemoveAll(false);
  
      setUsers((prev) => prev.map((user) => ({ ...user, is_restricted: false })));
      setCheckedState((prev) => prev.map(() => true));
      setUpdateUsers([]);
  };
  
  const removeAllCheck = () => {
    setRemoveAll(true);

    setUsers((prev) =>
        prev.map((user) => ({
            ...user,
            is_restricted: true,
        }))
    );

    setCheckedState(users.map(() => false));

    setUpdateUsers(users.map((user) => ({
        ...user,
        is_restricted: true
    })));
  };

  useEffect(() => {
    if (profile) {
        setCompany(parseInt(atob(profile?.profile?.company?.id).split(":")[1]));
    }
  }, [profile]);

  const createCategory = async () => {
    setLoading(true);
    const data = await storage.getItem("data");

    let _users = updateUsers.map((u) =>
        btoa(JSON.stringify({ id: u.id, is_restricted: u.is_restricted }))
    );

    try {
        const response = await api.createCategory(
            data.token,
            name,
            limitValue,
            JSON.stringify(_users),
            company,
            removeAll
        );

        if (response.data.createCategories.createCategoriesError) {
            const errorMessage =
                response.data.createCategories.createCategoriesError.messages[0];

            if (errorMessage === `Category ${name} cannot be used`) {
                setErrorMessage("Já existe uma categoria com esse nome");
            } else {
                reset();
                close();
            }
        }
    } catch (error) {
        setErrorMessage("Ocorreu um erro inesperado");
    }
    setLoading(false);
};
  
  const reset = () => {
      setLimitValue(0);
      setName("");
      setSearch("");
      setUsers([]);
      setEndCursor(null);
      setRemoveAll(false);
      setCheckedState(users.map(() => true));
      setSelectAllChecked(true);
      setUpdateUsers(users.map((user) => ({
        ...user,
        is_restricted: false
      })));
      setErrorMessage(false);
  };
  
  const defaultMaskOptions = {
      prefix: "R$ ",
      suffix: "",
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: "",
      allowDecimal: true,
      decimalSymbol: ",",
      decimalLimit: 2,
      integerLimit: 7,
      allowNegative: false,
      allowLeadingZeroes: false,
  };
  
  const currencyMask = createNumberMask({ ...defaultMaskOptions });
  return (
    <>
      {loading && <Loader />}
      <Modal
        open={open}
        onClose={() => {
          close();
          reset();
          setEndCursor(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCenter
          style={{
            width: 1000,
            maxWidth: "60%",
            height: 1280,
            maxHeight: "90%",
            overflowY: "auto",
          }}
        >
          <ModalClose
            src="/img/modal_close.svg"
            onClick={() => {
              close();
              reset();
            }}
          />
          {loading && <Loader inside={true} />}
          <Clear />
          <ModalView>
            <BasicTitle title={"ADICIONAR CATEGORIA"} margin={32} />
            <div>
              <Button
                onClick={toggleDropdown1}
                variant="outlined"
                style={{
                  border: "none",
                  marginTop: 15,
                  padding: 0,
                }}
              >
                {isOpen1 ? (
                  <ImageIcon src="/img/arrow_up.svg" alt="" />
                ) : (
                  <ImageIcon src="/img/arrow_down.svg" alt="" />
                )}
                DETALHES
              </Button>
              <Box
                style={{
                  paddingBottom: 10,
                  border: "none",
                  borderBottom: "1px solid #E6E6E6",
                  width: "100%",
                  paddingTop: 20,
                  paddingLeft: 40,
                }}
              >
                <Collapse in={isOpen1}>
                  <FieldContainer>
                    <SubTitle>NOME</SubTitle>
                    <TextField
                      id="outlined-basic"
                      value={name}
                      variant="outlined"
                      style={{ borderRadius: 5, width: "30%" }}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FieldContainer>
                </Collapse>
              </Box>
              <Button
                onClick={toggleDropdown2}
                variant="outlined"
                style={{
                  border: "none",
                  marginTop: 15,
                  padding: 0,
                }}
              >
                {isOpen2 ? (
                  <ImageIcon src="/img/arrow_up.svg" alt="" />
                ) : (
                  <ImageIcon src="/img/arrow_down.svg" alt="" />
                )}
                LIMITES
              </Button>
              <Box
                style={{
                  paddingBottom: 10,
                  border: "none",
                  borderBottom: "1px solid #E6E6E6",
                  width: "100%",
                  paddingTop: 20,
                  paddingLeft: 40,
                }}
              >
                <Collapse in={isOpen2}>
                  <FieldContainer>
                    <SubTitle>VALOR LIMITE</SubTitle>
                    <MaskedInput
                      mask={currencyMask}
                      placeholder="R$ 0.00"
                      style={{
                        fontSize: 16,
                        padding: 14,
                        border: "1px solid #C1C4C8",
                        borderRadius: 4,
                        marginRight: 16,
                        width: 200,
                        fontWeight: 400,
                      }}
                      onChange={(event) => {
                        let _value = event.target.value;
                        if (_value) {
                          _value = _value.replace("R$ ", "");
                          _value = parseFloat(_value.replace(",", "."));
                          setLimitValue(_value);
                        }
                      }}
                    />
                  </FieldContainer>
                </Collapse>
              </Box>
              <Button
                onClick={toggleDropdown3}
                variant="outlined"
                style={{
                  border: "none",
                  marginTop: 15,
                  padding: 0,
                }}
              >
                {isOpen3 ? (
                  <ImageIcon src="/img/arrow_up.svg" alt="" />
                ) : (
                  <ImageIcon src="/img/arrow_down.svg" alt="" />
                )}
                USUÁRIOS
              </Button>
              <Box style={{ marginTop: 20, marginLeft: 40 }}>
                <Title>BUSCAR USUÁRIO</Title>
                <TextField
                  value={search}
                  onChange={handleInputChange}
                  variant="outlined"
                  style={{ width: "282px", height: "35px", borderRadius: 8 }}
                  InputProps={{
                    endAdornment: search ? (
                      <IconButton onClick={handleClearSearch} edge="end">
                        <ClearIcon />
                      </IconButton>
                    ) : null,
                  }}
                />
                <Button
                  style={{ marginLeft: 16, fontSize: 12, padding: "10px", height: 57, borderRadius: 8 }}
                  variant="contained"
                  disableelevation="true"
                  color="primary"
                  onClick={handleSearch}
                >
                  <img src="/img/search.svg" alt="Filtro" style={{ width: 25, height: 25 }} />
                </Button>
              </Box>
              <Box
                style={{
                  paddingBottom: 10,
                  border: "none",
                  borderBottom: "1px solid #E6E6E6",
                  width: "100%",
                  paddingTop: 20,
                  paddingLeft: 40,
                }}
              >
                <Collapse in={isOpen2}>
                <View
                  id="scrollable-view"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: 300,
                    overflowY: "auto",
                  }}
                  onScroll={(e) => handleScroll(e)}
                >
                  <View>
                    <CheckBox
                      style={{ marginBottom: 15 }}
                      type="checkbox"
                      checked={selectAllChecked}
                      onChange={() => selectedAll()}
                    />
                    <Item style={{ margin: 0, fontWeight: 600 }}>SELECIONAR TUDO</Item>
                  </View>
                  {users.map((user, index) => (
                    <View key={user.id} style={{ marginBottom: 15 }}>
                      <CheckBox
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        value={user.name}
                        checked={checkedState[index]}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <Item style={{ margin: 0 }}>{user.name}</Item>
                    </View>
                  ))}
                  {loadingUsers && (
                    <View style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
                      <CircularProgress size={24} />
                    </View>
                  )}
                </View>
                  <View>
                    <Button
                      style={{ color: "#DE6061", marginTop: 15 }}
                      onClick={() => {
                        removeAllCheck();
                      }}
                    >
                      REMOVER TODOS
                    </Button>
                  </View>
                </Collapse>
              </Box>
            </div>
          </ModalView>
          <ModalFooter>
            <div>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <Button
                onClick={() => {
                  createCategory();
                }}
              >
                SALVAR
              </Button>
              <Button 
                style={{ color: "#DE6061" }}
                onClick={() =>{
                  close();
                  reset();
                }}
              >
                CANCELAR
              </Button>
            </div>
          </ModalFooter>
        </ModalCenter>
      </Modal>
    </>
  );
}
