import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import BasicTitle from "../components/title";

import Api from "../libs/api";
import Storage from "../libs/storage";
import Helper from "../libs/helper";
import { useImpersonation } from "../global/impersonation";

const Holder = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
    margin-bottom: 20px;
`;

const View = styled.div`
    display: flex;
    flex-direction: row;
`;

const Box = styled.div`
    width: 200px;
    height: 100px;
    border-radius: 8px;
    background-color: #e6e6e6;
    margin-right: 15px;
    flex-direction: column;
    display: flex;
    padding: 25px;
`;

const Content = styled.div`
    display: flex;
    align-itens: center;
    flex-direction: row;
    width: 100%;
`;

const Pointer = styled.div`
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: black;
    margin-top: 5px;
`;
const Text = styled.p`
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #181122;
    text-transform: uppercase;
    padding-left: 5px;
`;

const Value = styled.p`
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #181122;
    text-transform: uppercase;
    width: 100%;
    padding-top: 10px;
`;

export default function Summary({
    month,
    year,
    selectedUsers,
    startDay = null,
    endDay = null,
    startMonth = null,
    endMonth = null,
    startYear = null,
    endYear = null,
    selectedStatus,
    selectedProjects,
    selectedCategories,
    corporate = null,
    warning = null,
    refreshSummary,
    applyFilters
}) {
    let api = Api();
    let storage = Storage();
    let helper = Helper();
    const { impersonateCompanyId } = useImpersonation();

    const [sumRefunds, setSumRefunds] = useState("");
    const [totalValue, setTotalValue] = useState(helper.floatToMoney(0));
    const [totalApproved, setTotalApproved] = useState(helper.floatToMoney(0));
    const [totalPending, setTotalPending] = useState(helper.floatToMoney(0));
    const [totalPaid, setTotalPaid] = useState(helper.floatToMoney(0));
    const [totalRejected, setTotalRejected] = useState(helper.floatToMoney(0));
    const [totalCorporateCard, setTotalCorporateCard] = useState(
        helper.floatToMoney(0)
    );
    const [users, setUsers] = useState(null);
    const [status, setStatus] = useState(null);
    const [projects, setProjects] = useState(null);
    const [categories, setCategories] = useState(null);

    const isFetching = useRef(false);

    const getSummaryRefunds = async () => {
        if (!isFetching.current) {
            isFetching.current = true;

            const data = await storage.getItem("data");
            if (!data) return;
            const response = await api.getRefundSummary(
                data.token,
                users,
                year,
                month,
                startDay,
                endDay,
                startMonth,
                endMonth,
                startYear,
                endYear,
                status,
                projects,
                categories,
                corporate,
                warning,
                impersonateCompanyId
            );
            setSumRefunds(response.data.sumValues);

            isFetching.current = false;
        }
    };

    useEffect(() => {
        if (selectedProjects) {
            const projectIds = selectedProjects.map((project) =>
                parseInt(atob(project?.id).split(":")[1])
            );
            setProjects(projectIds);
        }
    }, [selectedProjects]);

    useEffect(() => {
        if (selectedCategories) {
            const categoryIds = selectedCategories.map((category) =>
                parseInt(atob(category?.id).split(":")[1])
            );
            setCategories(categoryIds);
        }
    }, [selectedCategories]);

    useEffect(() => {
        const statusArray= selectedStatus.map(status => parseInt(status, 10));
        setStatus(statusArray);
    }, [selectedStatus]);
    

    useEffect(() => {
        if (selectedUsers) {
            const userIds = selectedUsers.map((user) =>
                parseInt(atob(user?.id).split(":")[1])
            );
            setUsers(userIds);
        }
    }, [selectedUsers]);

    useEffect(() => {
        if (sumRefunds) {
            setTotalValue(
                sumRefunds?.sumValues?.total
                    ? helper.floatToMoney(sumRefunds?.sumValues?.total)
                    : helper.floatToMoney(0)
            );
            setTotalApproved(
                sumRefunds?.sumValues?.approved
                    ? helper.floatToMoney(sumRefunds?.sumValues?.approved)
                    : helper.floatToMoney(0)
            );
            setTotalPending(
                sumRefunds?.sumValues?.pending
                    ? helper.floatToMoney(sumRefunds?.sumValues?.pending)
                    : helper.floatToMoney(0)
            );
            setTotalPaid(
                sumRefunds?.sumValues?.paid
                    ? helper.floatToMoney(sumRefunds?.sumValues?.paid)
                    : helper.floatToMoney(0)
            );
            setTotalRejected(
                sumRefunds?.sumValues?.rejected
                    ? helper.floatToMoney(sumRefunds?.sumValues?.rejected)
                    : helper.floatToMoney(0)
            );
            setTotalCorporateCard(
                sumRefunds?.sumValues?.totalCorporateCard
                    ? helper.floatToMoney(
                          sumRefunds?.sumValues?.totalCorporateCard
                      )
                    : helper.floatToMoney(0)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sumRefunds]);

    useEffect(() => {
        getSummaryRefunds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getSummaryRefunds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        applyFilters,
        users,
        year,
        month,
        startDay,
        endDay,
        startMonth,
        endMonth,
        startYear,
        endYear,
        status,
        projects,
        categories,
        warning,
        corporate,
        refreshSummary,
    ]);

    return (
        <>
            <BasicTitle title={"Resumo"} />
            <Holder>
                <View>
                    <Box>
                        <Content>
                            <Pointer />
                            <Text>TOTAL</Text>
                        </Content>
                        <Value>{totalValue}</Value>
                    </Box>
                    <Box>
                        <Content>
                            <Pointer />
                            <Text>PENDENTES</Text>
                        </Content>
                        <Value>{totalPending}</Value>
                    </Box>
                    <Box>
                        <Content>
                            <Pointer style={{ backgroundColor: "#57C4C2" }} />
                            <Text>APROVADOS</Text>
                        </Content>
                        <Value>{totalApproved}</Value>
                    </Box>
                    <Box>
                        <Content>
                            <Pointer style={{ backgroundColor: "#DE6061" }} />
                            <Text>REJEITADOS</Text>
                        </Content>
                        <Value>{totalRejected}</Value>
                    </Box>
                    <Box>
                        <Content>
                            <Pointer style={{ backgroundColor: "#57C4C2" }} />
                            <Text>PAGOS</Text>
                        </Content>
                        <Value>{totalPaid}</Value>
                    </Box>
                    <Box>
                        <Content>
                            <Pointer style={{ backgroundColor: "#78737F" }} />
                            <Text>CARTÃO CORP.</Text>
                        </Content>
                        <Value>{totalCorporateCard}</Value>
                    </Box>
                </View>
            </Holder>
        </>
    );
}
