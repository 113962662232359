export const DownloadAddUsersExample = () => {
  const csvData = `"Nome: Preencha o nome do usuário.\nPode-se utilizar acentos.\nESTE CAMPO É OBRIGATÓRIO","E-MAIL: Preencha o e-mail do usuário.\nESTE CAMPO É OBRIGATÓRIO"
  "jane doe","jane@doe.com"
  "john doe","john@doe.com"
  "jane doe","jane@doe.com"
  "john doe","john@doe.com"
  "jane doe","jane@doe.com"`;

  const blob = new Blob([csvData], { type: 'text/csv' });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'template.csv');

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

  export const DownloadAddUsers = () => {
    const csvData = `"Nome: Preencha o nome do usuário.\nPode-se utilizar acentos.\nESTE CAMPO É OBRIGATÓRIO","E-MAIL: Preencha o e-mail do usuário.\nESTE CAMPO É OBRIGATÓRIO"`;
  
    const blob = new Blob([csvData], { type: 'text/csv' });
  
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'template.csv');
  
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  };
  
  