import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import { ptBR as dPtBr } from "@mui/x-data-grid/locales";


const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme(
  {
    palette: {
      mode: "light",
      primary: {
        main: "#57C4C2",
      },
      secondary: {
        main: "#000000",
      },
      error: {
        main: "#DE6061",
      },
      // whatever
      success: {
        main: "#57C4C2",
      },
      warning: {
        main: "#57C4C2",
      },
    },
  },
  ptBR,
  dPtBr
);

root.render(
  <BrowserRouter
    future={{
      v7_startTransition: true,
      v7_relativeSplatPath: true,
    }}
  >
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);

reportWebVitals();
