import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Api from "../libs/api";
import Helper from "../libs/helper";
import Loader from "../components/loader";
import Button from "@mui/material/Button";

import { useParams } from "react-router-dom";

const Holder = styled.div`
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 676px;
`;

const Card = styled.div`
  background: #fff;
  padding: 56px;
  text-align: center;
  width: 100%;
  max-width: 488px;
  border-radius: 8px;
`;

const Logo = styled.img`
  width: 42px;
  margin-bottom: 40px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #181122;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #181122;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

const Clear = styled.div`
  clear: both;
`;

const Field = styled.div`
  width: 100%;
  max-width: 327px;
  margin: 0 auto;
  margin-bottom: 24px;
  position: relative;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #181122;
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
`;

const Input = styled.input`
  border: 1px solid #181122;
  width: 100%;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #181122;
  padding: 16px;
  padding-right: 50px;
  &.error {
    border: 1px solid #de6061;
  }
`;

const Submit = styled.div`
  width: 100%;
  max-width: 327px;
  margin: 0 auto;
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #de6061;
  width: 100%;
  text-align: left;
`;

const Eye = styled.img`
  width: 12px;
  height: 12px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
`;

export default function ResetPasswordCommit() {
  // instances
  const api = Api();
  const helper = Helper();

  let navigate = useNavigate();

  // commit
  const { token } = useParams();

  // states
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [reseted, setReseted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }

  const commitResetPassword = async () => {
    if (!password || !confirmPassword) {
      setErrorMessage("Preencha a nova senha");
    } else {
      if (password !== confirmPassword) {
        setErrorMessage("As senhas precisam ser iguais");
      } else {
        if (!helper.containsNumbers(password)) {
          setErrorMessage("A senha precisa conter pelo menos 1 número");
        } else {
          if (password.length < 8) {
            setErrorMessage("A senha precisa ter pelo menos 8 caracteres");
          } else {
            setLoading(true);
            const response = await api.commitResetPassword(
              password,
              confirmPassword,
              token
            );
            setLoading(false);
            if (
              response.data.resetPasswordCommit.resetPasswordError
                .messages[0] === "Password successfully changed"
            ) {
              setReseted(true);
            } else {
              setErrorMessage(
                "Ocorreu um erro ao alterar sua senha. Tente novamente."
              );
              setPassword(null);
              setConfirmPassword(null);
            }
          }
        }
      }
    }
  };

  function back() {
    navigate("/login");
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
  }, []);

  //   useEffect(() => {
  //     console.log(token);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [token]);

  return (
    <>
      {loading && <Loader />}
      <Holder style={{ height: height, width: width }}>
        <Card>
          <Logo src="/img/logo.png" />
          <Clear />
          {reseted ? (
            <>
              <br />
              <br />
              <img src="/img/man.png" width="245" alt="Man" />
              <SubTitle>
                <br />
                <br />
                SENHA ALTERADA COM SUCESSO.
                <br />
              </SubTitle>
              <br />
              <br />
              <Button
                style={{
                  width: "100%",
                  maxWidth: 327,
                  fontWeight: 700,
                  fontSize: 12,
                  padding: "12px 0px",
                  marginTop: 12,
                  color: "#000",
                }}
                variant="contained"
                disableelevation="true"
                color="primary"
                onClick={back}
              >
                VOLTAR AO LOGIN
              </Button>
            </>
          ) : (
            <>
              <Title>Recuperação de senha</Title>
              <SubTitle>
                Sua nova senha precisa ter pelo menos{" "}
                <strong>8 caracteres</strong> e pelo menos{" "}
                <strong>1 número</strong>
              </SubTitle>
              <Field>
                <Label>SENHA</Label>
                <Input
                  type={showPassword ? "text" : "password"}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  autoFocus
                />
                <Eye
                  src={showPassword ? "/img/eye_on.png" : "/img/eye_off.png"}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              </Field>
              <Field>
                <Label>CONFIRMAR SENHA</Label>
                <Input
                  type={showPassword ? "text" : "password"}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                />
                <Eye
                  src={showPassword ? "/img/eye_on.png" : "/img/eye_off.png"}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              </Field>
              {errorMessage && (
                <Field>
                  <Error>{errorMessage}</Error>
                </Field>
              )}
              <Submit>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: 327,
                    fontWeight: 700,
                    fontSize: 12,
                    padding: "12px 0px",
                  }}
                  variant="contained"
                  disableelevation="true"
                  color="primary"
                  onClick={commitResetPassword}
                >
                  ALTERAR SENHA
                </Button>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: 327,
                    fontWeight: 700,
                    fontSize: 12,
                    padding: "12px 0px",
                    marginTop: 12,
                    color: "#000",
                  }}
                  color="secondary"
                  onClick={back}
                >
                  VOLTAR AO LOGIN
                </Button>
              </Submit>
            </>
          )}
        </Card>
      </Holder>
    </>
  );
}
