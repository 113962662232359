import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Content from "../components/content";
import Loader from "../components/loader";

import AddUser from "../components/add_user";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import BasicTitle from "../components/title";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import Modal from "@mui/material/Modal";

import { useImpersonation } from "../global/impersonation";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid";

import Api from "../libs/api";
import Storage from "../libs/storage";
import EditUser from "../components/edit_user";

const Holder = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
`;

const Filters = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 56px;
`;

const LeftFilters = styled.div`
    min-width: 100px;
    display: flex;
    align-items: center;
`;

const RightFilters = styled.div`
    min-width: 100px;
    display: flex;
    align-items: center;
`;

const Tag = styled.div`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
    padding: 4px 10px;
    border-radius: 8px;

    &.pendente {
        color: #181122;
        border: 1px solid #181122;
    }
    &.aprovado {
        color: #57c4c2;
        border: 1px solid #57c4c2;
    }
    &.rejeitado {
        color: #de6061;
        border: 1px solid #de6061;
    }
    &.pago {
        color: #57c4c2;
        border: 1px solid #57c4c2;
    }
`;

const Data = styled.div`
    margin-bottom: 40px;
`;

const ImageClickable = styled.img`
    cursor: pointer;
`;

const Vision = styled.div`
    height: 50px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
`;

const VisionCard = styled.div`
    width: 100%;
`;
const VisionCardTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #000000;
`;
const VisionCardSubTitle = styled.div`
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
    text-transform: uppercase;
    color: #909090;
`;

const ModalClose = styled.img`
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;

const ModalConfirmation = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    height: auto;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid rgb(144, 144, 144);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalAlert = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    height: auto;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid rgb(144, 144, 144);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalPadding = styled.div`
    padding: 20px;
`;

const Field = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
`;

const View = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const TextModal = styled.p`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    border-radius: 8px;
    margin-left: 20px;
    margin-bottom: 15px;
`;

export default function Users() {
    let api = Api();
    let storage = Storage();
    const { impersonateCompanyId } = useImpersonation();

    const [rows, setRows] = useState([
        {
            id: 0,
            name: "---",
            email: "---",
            disabled: "---",
            role: "---",
            actions: {
                id: 0,
                role: 0,
                disabled: false,
            },
        },
    ]);

    const [loading, setLoading] = useState(false);

    const [status, setStatus] = useState("all");
    const [perfil, setPerfil] = useState("all");

    const [first, setFirst] = useState(true);
    const [overview, setOverview] = useState({
        all: 0,
        active: 0,
        inactive: 0,
    });

    const RenderStatus = (props) => {
        const { value } = props;

        if (value === false) {
            return <Tag className="pendente">ATIVO</Tag>;
        }
        if (value === true) {
            return <Tag className="rejeitado">INATIVO</Tag>;
        }
        return <>{value}</>;
    };

    const RenderRole = (props) => {
        const { value } = props;

        if (value === 0) {
            return <Tag className="pendente">Usuário</Tag>;
        }
        if (value === 1) {
            return <Tag className="rejeitado">Administrador</Tag>;
        }
        if (value === 2) {
            return <Tag className="rejeitado">Proprietário</Tag>;
        }
        if (value === 3) {
            return <Tag className="rejeitado">Owner</Tag>;
        }
        return <>{value}</>;
    };

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openConfirmationDeleted, setOpenConfirmationDeleted] =
        useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [disableMe, setDisableMe] = useState(false);
    const [confirmationUser, setConfirmationUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [profile, setProfile] = useState(null);

    const RenderActions = (props) => {
        const { value, row } = props;

        if (value.role >= 2) {
            return <></>
        }

        if (impersonateCompanyId != null) {
            return (
                <>
                    {value.disabled && (
                        <Tooltip title="Ativar" placement="top" arrow>
                            <ImageClickable
                                src="/img/pay_paid.svg"
                                alt="Ativar"
                                style={{ marginRight: 16, opacity: 0.8, pointerEvents: "none" }}
                            />
                        </Tooltip>
                    )}
                    {!value.disabled && (
                        <Tooltip title="Desativar" placement="top" arrow>
                            <ImageClickable
                                src="/img/rejected.svg"
                                alt="Desativar"
                                style={{ marginRight: 16, opacity: 0.4 }}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title="Deletar" placement="top" arrow>
                        <ImageClickable
                            src="/img/trash.png"
                            alt="Deletar"
                            style={{ marginRight: 16, width: 20, height: 20, opacity: 0.4 }}
                        />
                    </Tooltip>
                </>
            )
        }

        return (
            <>
                {value.disabled && (
                    <Tooltip title="Ativar" placement="top" arrow>
                        <ImageClickable
                            src="/img/pay_paid.svg"
                            alt="Ativar"
                            style={{ marginRight: 16 }}
                            onClick={(event) => handleRowClick(row, event)}
                        />
                    </Tooltip>
                )}
                {!value.disabled && (
                    <Tooltip title="Desativar" placement="top" arrow>
                        <ImageClickable
                            src="/img/rejected.svg"
                            alt="Desativar"
                            style={{ marginRight: 16 }}
                            onClick={(event) => handleRowClick(row, event)}
                        />
                    </Tooltip>
                )}
                <Tooltip title="Deletar" placement="top" arrow>
                    <ImageClickable
                        src="/img/trash.png"
                        alt="Deletar"
                        style={{ marginRight: 16, width: 20, height: 20 }}
                        onClick={(event) => handleRowClick(row, event)}
                    />
                </Tooltip>
            </>
        )
    };

    const handleRejectButton = () => {
        setOpenConfirmation(false);
        setOpenAlert(false);
        setOpenConfirmationDeleted(false);
        setDisableMe(false);
    };

    const columns = [
        { field: "id", headerName: "ID", width: 100 },
        { field: "name", headerName: "Nome", width: 300 },
        { field: "email", headerName: "Email", width: 300 },
        {
            field: "role",
            width: 140,
            headerName: "Perfil",
            sortable: false,
            renderCell: RenderRole,
        },
        {
            field: "disabled",
            width: 140,
            headerName: "Status",
            renderCell: RenderStatus,
        },
        {
            field: "actions",
            headerName: "Ativar/Desativar",
            sortable: false,
            disableExport: true,
            width: 150,
            renderCell: RenderActions,
        },
    ];

    const getProfile = async () => {
        const data = await storage.getItem("data"); 
        if (!data) return;
        setProfile(data.profile); 
    };
    

    const getUsers = async () => {
        setLoading(true);
        const data = await storage.getItem("data");
        if (!data) return;
        let response = await api.getUsersFiltered(
            data.token,
            status,
            perfil,
            impersonateCompanyId
        );
        response = response.data.allZagviewUsers.edges;
        // setUsers(response);
        let _rows = [];
        let _all = 0;
        let _active = 0;
        let _inactive = 0;
        for (let index = 0; index < response.length; index++) {
            const user = response[index].node;
            const _user = {
                id: parseInt(atob(user.id).split(":")[1]),
                name: user.name,
                email: user.email,
                disabled: user.disabled,
                role: user.role,
                deleted: user.deleted,
                company: {
                    // id: parseInt(atob(user.company.id).split(":")[1]),
                    id: parseInt(user.company.id),
                    name: user.company.name,
                },
                actions: {
                    id: user.id,
                    role: user.role,
                    disabled: user.disabled,
                },
            };
            _rows.push(_user);

            if (first) {
                _all = _all + 1;
                if (user.disabled) {
                    _inactive = _inactive + 1;
                } else {
                    _active = _active + 1;
                }
                setOverview({
                    all: _all,
                    active: _active,
                    inactive: _inactive,
                });
                setFirst(false);
            }
        }
        setRows(_rows);
        setLoading(false);
    };

    useEffect(() => {
        getUsers(status, perfil);
        getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, perfil]);

    const [selectedCells, setSelectedCells] = useState([]);

    const handleChangeCellSelection = (refunds) => {
        setSelectedCells(refunds);
    };

    const updateUsers = async (disabled) => {
        let _users = [];
        if (selectedCells.length > 0) {
            setLoading(true);
            for (let index = 0; index < selectedCells.length; index++) {
                const id = selectedCells[index];
                _users.push(
                    btoa(
                        JSON.stringify({
                            id,
                            disabled,
                        })
                    )
                );
            }
            const data = await storage.getItem("data");
            await api.updateUsers(data.token, _users, impersonateCompanyId);
            getUsers(status, perfil);
        }
    };

    const updateUser = async (id, disabled) => {
        setLoading(true);
        let _user = [
            btoa(
                JSON.stringify({
                    id,
                    disabled,
                })
            ),
        ];
        const data = await storage.getItem("data");
        await api.updateUsers(data.token, _user);
        getUsers(status, perfil, impersonateCompanyId);
        setLoading(false);
    };

    const deletedUser = async (email, company) => {
        setLoading(true);
        const data = await storage.getItem("data");
        await api.deletedUsers(data.token, email, company);
        getUsers(status, perfil, impersonateCompanyId);
        setLoading(false);
    };

    function toolbarDatagridOptions() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        window.addEventListener("load", handleWindowSizeChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [banks, setBanks] = useState([]);
    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);

    const getModalData = async () => {
        const data = await storage.getItem("data");
        if (!data) return;
        let response = await api.getCategories(
            data.token,
            impersonateCompanyId
        );
        response = response.data.allCategories.edges;
        let _categories = [];
        for (let index = 0; index < response.length; index++) {
            const category = response[index].node;
            _categories.push(category);
        }
        setCategories(_categories);

        response = await api.getProjects(data.token, impersonateCompanyId);
        response = response.data.allProjects.edges;
        let _projects = [];
        for (let index = 0; index < response.length; index++) {
            const project = response[index].node;
            _projects.push(project);
        }
        setProjects(_projects);

        response = await api.getBanks(data.token);
        response = response.data.allBanks.edges;
        let _banks = [];
        for (let index = 0; index < response.length; index++) {
            const bank = response[index].node;
            _banks.push(bank);
        }
        setBanks(_banks);
    };

    const [anchorElMenuAdd, setAnchorElMenuAdd] = React.useState(null);
    const openMenuAdd = Boolean(anchorElMenuAdd);
    const handleClickMenuAdd = (event) => {
        setAnchorElMenuAdd(event.currentTarget);
    };
    const handleCloseMenuAdd = () => {
        setAnchorElMenuAdd(null);
        setOpenAddOne(false);
        setOpenAddMultiple(false);
    };

    const [openAddOne, setOpenAddOne] = useState(false);
    const [openAddMultiple, setOpenAddMultiple] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);

    const handleCloseModal = () => {
        setAnchorElMenuAdd(null);
        setOpenAddOne(false);
        setOpenAddMultiple(false);
        setOpenEditUser(false);
    };

    const handleOpenAddOne = () => {
        setAnchorElMenuAdd(null);
        setOpenAddOne(true);
        setOpenAddMultiple(false);
        setOpenEditUser(false);
    };

    const handleOpenAddMultiple = () => {
        setAnchorElMenuAdd(null);
        setOpenAddMultiple(true);
        setOpenAddOne(false);
        setOpenEditUser(false);
    };

    const handleOpenAlert = () => {
        setAnchorElMenuAdd(null);
        setOpenAddMultiple(false);
        setOpenAddOne(false);
        setOpenEditUser(false);
        setOpenAlert(true);
    };

    const [editUser, setEditUser] = useState();

    const handleOpenEditUser = async (row, event, details) => {
        setLoading(true);
        const data = await storage.getItem("data");
        const id = btoa(`ZagviewUsersWithCompanyNode:${row.id}`);
        const email = data.profile.email;

        if (row.email === email) {
            let response = await api.getUserEdit(
                data.token,
                id,
                impersonateCompanyId
            );
            setEditUser(response.data.userWithCompany);
            setAnchorElMenuAdd(null);
            setOpenAddMultiple(false);
            setOpenAddOne(false);
            setOpenEditUser(true);
        } else {
            if (row.role === 2) {
                handleOpenAlert();
            } else {
                let response = await api.getUserEdit(
                    data.token,
                    id,
                    impersonateCompanyId
                );
                setEditUser(response.data.userWithCompany);
                setAnchorElMenuAdd(null);
                setOpenAddMultiple(false);
                setOpenAddOne(false);
                setOpenEditUser(true);
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        getModalData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRowClick = async (row, event) => {
        if (event.target.nodeName !== "IMG") {
            await handleOpenEditUser(row);
        } 
        
        if (impersonateCompanyId !== null) {
            return;
        }
        
        else {
            const actionType = event.target.alt;
            switch (actionType) {
                case "Ativar":
                    setSelectedUser(row);
                    setConfirmationUser(true);
                    setOpenConfirmation(true);
                    break;
                case "Desativar":
                    if (profile && row.email === profile.email) {
                        setSelectedUser(row);
                        setDisableMe(true);
                    } else {
                        setSelectedUser(row);
                        setConfirmationUser(false);
                        setOpenConfirmation(true);
                    }
                    break;
                case "Deletar":
                    setSelectedUser(row);
                    setOpenConfirmationDeleted(true);
                    break;
                default:
                    break;
            }
        }
    };

    const logoutAndClearStorage = () => {
        storage.removeItem(); 
        window.location.href = "/login";
    };
    
    return (
        <>
            {loading && <Loader />}
            <AddUser
                type={"one"}
                title={"Cadastrar Usuário"}
                open={openAddOne}
                close={handleCloseModal}
                banks={banks}
                projects={projects}
                categories={categories}
                getUsers={getUsers}
            />
            <AddUser
                type={"many"}
                title={"Cadastrar em Lote"}
                open={openAddMultiple}
                close={handleCloseModal}
                banks={banks}
                projects={projects}
                categories={categories}
                getUsers={getUsers}
            />
            {/* <EditUsers
        title={"Editar em Lote"}
        open={openEditMultiple}
        close={handleCloseModal}
        projects={projects}
        categories={categories}
        getUsers={getUsers}
      /> */}
            {editUser && (
                <EditUser
                    user={editUser}
                    title={"Editar Usuário"}
                    open={openEditUser}
                    close={handleCloseModal}
                    banks={banks}
                    projects={projects}
                    categories={categories}
                    getUsers={getUsers}
                    impersonateCompanyId={impersonateCompanyId}
                />
            )}
            <Modal
                open={openAlert}
                onClose={handleRejectButton}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalAlert>
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={handleRejectButton}
                    />
                    <ModalPadding>
                        <BasicTitle title={"Alerta"} />
                        <TextModal style={{ color: "#DE6061" }}>
                            Você não pode editar outro proprietário.
                        </TextModal>
                    </ModalPadding>
                </ModalAlert>
            </Modal>
            <Modal
                open={disableMe}
                onClose={handleRejectButton}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalAlert>
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={handleRejectButton}
                    />
                    <ModalPadding>
                        <BasicTitle title={"DESATIVAR SEU PRÓPRIO USUÁRIO"} />
                        <TextModal style={{ fontSize: 12, lineHeight: "20px", marginLeft: 0 }}>
                            AO CLICAR EM DESATIVAR, SUA SOLICITAÇÃO SERÁ EFETUADA. LEMBRE-SE, SOMENTE OUTRO ADMINISTRADOR PODERÁ REATIVAR SEU PERFIL.
                        </TextModal>
                        <View>
                            <Button
                                variant="contained"
                                color={"primary"}
                                style={{ fontSize: 13 }}
                                onClick={() => {
                                    updateUser(selectedUser.id, true);
                                    setDisableMe(false);
                                    logoutAndClearStorage();
                                }}
                            >
                                DESATIVAR
                            </Button>
                        </View>
                    </ModalPadding>
                </ModalAlert>
            </Modal>
            <Modal
                open={openConfirmationDeleted}
                onClose={handleRejectButton}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalConfirmation>
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={handleRejectButton}
                    />
                    <ModalPadding>
                        <BasicTitle title={"EXCLUIR USUÁRIO"} />
                    </ModalPadding>
                    <Field>
                        <TextModal
                            style={{
                                textAlign: "left",
                                fontSize: 12,
                                lineHeight: 2,
                            }}
                        >
                            AO CLICAR EM <strong>EXCLUIR</strong>, SUA
                            SOLICITAÇÃO SERÁ
                            <br /> EFETUADA. EM CASO DE EXCLUSÃO, NÃO SERÁ
                            <br /> POSSÍVEL RECUPERAR O PERFIL.
                        </TextModal>
                        <View>
                            <Button
                                variant="contained"
                                color={"primary"}
                                style={{ fontSize: 13 }}
                                onClick={() => {
                                    deletedUser(
                                        selectedUser.email,
                                        selectedUser.company.id
                                    );
                                    setSelectedUser(null);
                                    handleRejectButton();
                                }}
                            >
                                EXCLUIR
                            </Button>
                        </View>
                    </Field>
                </ModalConfirmation>
            </Modal>
            <Modal
                open={openConfirmation}
                onClose={handleRejectButton}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalConfirmation>
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={handleRejectButton}
                    />
                    <ModalPadding>
                        <BasicTitle title={"CONFIRMAÇÃO"} />
                    </ModalPadding>
                    {confirmationUser ? (
                        <Field>
                            <TextModal style={{ textAlign: "left" }}>
                                Tem certeza que deseja reativar o usuário?
                            </TextModal>
                            <View>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    style={{ fontSize: 13 }}
                                    onClick={() => {
                                        updateUser(selectedUser.id, false);
                                        setSelectedUser(null);
                                        handleRejectButton();
                                    }}
                                >
                                    Sim, tenho certeza
                                </Button>
                                <Button
                                    style={{
                                        color: "#DE6061",
                                        fontSize: 13,
                                    }}
                                    onClick={() => {
                                        handleRejectButton();
                                        setSelectedUser(null);
                                    }}
                                >
                                    Descartar alteração
                                </Button>
                            </View>
                        </Field>
                    ) : (
                        <Field>
                            <TextModal>
                                Tem certeza que deseja inativar o usuário?
                            </TextModal>
                            <View>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    style={{ fontSize: 13 }}
                                    onClick={() => {
                                        updateUser(selectedUser.id, true);
                                        setSelectedUser(null);
                                        handleRejectButton();
                                    }}
                                >
                                    Sim, tenho certeza
                                </Button>
                                <Button
                                    style={{
                                        color: "#DE6061",
                                        fontSize: 13,
                                    }}
                                    onClick={() => {
                                        handleRejectButton();
                                        setSelectedUser(null);
                                    }}
                                >
                                    Descartar alteração
                                </Button>
                            </View>
                        </Field>
                    )}
                </ModalConfirmation>
            </Modal>
            <Holder>
                <Content title={"Usuários"}>
                    <Vision>
                        <VisionCard>
                            <VisionCardTitle>{overview.all}</VisionCardTitle>
                            <VisionCardSubTitle>
                                cadastrado(s)
                            </VisionCardSubTitle>
                        </VisionCard>
                        <VisionCard>
                            <VisionCardTitle>{overview.active}</VisionCardTitle>
                            <VisionCardSubTitle>ativo(s)</VisionCardSubTitle>
                        </VisionCard>
                        <VisionCard>
                            <VisionCardTitle>
                                {overview.inactive}
                            </VisionCardTitle>
                            <VisionCardSubTitle>inativo(s)</VisionCardSubTitle>
                        </VisionCard>
                    </Vision>
                    <BasicTitle title={"Lista Completa"} />
                    <Filters
                        style={{ display: width < 1000 ? "block" : "flex" }}
                    >
                        <LeftFilters
                            style={{ marginBottom: width < 1000 ? 16 : 0 }}
                        >
                            <FormControl
                                style={{
                                    width: width < 1000 ? "100%" : 200,
                                    marginRight: 16,
                                }}
                                size="small"
                            >
                                <InputLabel>Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={status}
                                    label="Status"
                                    onChange={(event) => {
                                        setStatus(event.target.value);
                                    }}
                                >
                                    <MenuItem value={"all"}>Todos</MenuItem>
                                    <MenuItem value={false}>Ativo</MenuItem>
                                    <MenuItem value={true}>Inativo</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                                style={{ width: width < 1000 ? "100%" : 200 }}
                                size="small"
                            >
                                <InputLabel>Perfil</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={perfil}
                                    label="Perfil"
                                    onChange={(event) => {
                                        setPerfil(event.target.value);
                                    }}
                                >
                                    <MenuItem value={"all"}>Todos</MenuItem>
                                    <MenuItem value={0}>Usuário</MenuItem>
                                    <MenuItem value={1}>Administrador</MenuItem>
                                    <MenuItem value={2}>Proprietário</MenuItem>
                                </Select>
                            </FormControl>
                        </LeftFilters>
                        <RightFilters
                            style={{ display: width < 1000 ? "block" : "flex" }}
                        >
                            <div>
                                {impersonateCompanyId === null ? (
                                    <Button
                                        style={{
                                            marginLeft: width < 1000 ? 0 : 16,
                                            marginBottom: width < 1000 ? 16 : 0,
                                            width: width < 1000 ? "100%" : 150,
                                            fontSize: 12,
                                            padding: "8px 0px",
                                        }}
                                        variant="contained"
                                        color={"primary"}
                                        onClick={handleClickMenuAdd}
                                    >
                                        + ADICIONAR
                                    </Button>
                                ) :(
                                    <Button
                                        style={{
                                            marginLeft: width < 1000 ? 0 : 16,
                                            marginBottom: width < 1000 ? 16 : 0,
                                            width: width < 1000 ? "100%" : 150,
                                            fontSize: 12,
                                            padding: "8px 0px",
                                        }}
                                        variant="outlined"
                                        color={"primary"}
                                    >
                                        + ADICIONAR
                                    </Button>
                                )}
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElMenuAdd}
                                    open={openMenuAdd}
                                    onClose={handleCloseMenuAdd}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    <MenuItem onClick={handleOpenAddOne}>
                                        Cadastro Único
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenAddMultiple}>
                                        Cadastro em Lote
                                    </MenuItem>
                                </Menu>
                            </div>
                            {/* <Button
                style={{
                  marginLeft: width < 1000 ? 0 : 16,
                  marginBottom: width < 1000 ? 16 : 0,
                  width: width < 1000 ? "100%" : 200,
                  fontSize: 12,
                  padding: "8px 0px",
                  // opacity: selectedCells.length > 0 ? 1 : 0.4,
                }}
                variant="contained"
                color={"primary"}
                onClick={() => {
                  setOpenEditMultiple(true);
                }}
                startIcon={<img src="/img/pencil.svg" alt="Editar" />}
              >
                EDITAR EM LOTE
              </Button> */}
                            {impersonateCompanyId === null ? (
                                <div>
                                    <Button
                                        style={{
                                            marginLeft: width < 1000 ? 0 : 16,
                                            marginBottom: width < 1000 ? 16 : 0,
                                            width: width < 1000 ? "100%" : 150,
                                            fontSize: 12,
                                            padding: "8px 0px",
                                            opacity: selectedCells.length > 0 ? 1 : 0.4,
                                        }}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            updateUsers(false);
                                        }}
                                    >
                                        ATIVAR EM LOTE
                                    </Button>
                                    <Button
                                        style={{
                                            marginLeft: width < 1000 ? 0 : 16,
                                            marginBottom: width < 1000 ? 16 : 0,
                                            width: width < 1000 ? "100%" : 180,
                                            fontSize: 12,
                                            padding: "8px 0px",
                                            opacity: selectedCells.length > 0 ? 1 : 0.4,
                                        }}
                                        variant="outlined"
                                        color={
                                            selectedCells.length > 0
                                                ? "error"
                                                : "secondary"
                                        }
                                        onClick={() => {
                                            updateUsers(true);
                                        }}
                                    >
                                        DESATIVAR EM LOTE
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        style={{
                                        marginLeft: width < 1000 ? 0 : 16,
                                        marginBottom: width < 1000 ? 16 : 0,
                                        width: width < 1000 ? "100%" : 150,
                                        fontSize: 12,
                                        padding: "8px 0px",
                                        }}
                                        variant="outlined"
                                        color="primary"     
                                    >
                                        ATIVAR EM LOTE
                                    </Button>
                                    <Button
                                        style={{
                                            marginLeft: width < 1000 ? 0 : 16,
                                            marginBottom: width < 1000 ? 16 : 0,
                                            width: width < 1000 ? "100%" : 180,
                                            fontSize: 12,
                                            padding: "8px 0px",
                                        }}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        DESATIVAR EM LOTE
                                    </Button>
                                </div>
                            )}          
                        </RightFilters>
                    </Filters>
                    <Data style={{ marginBottom: 100 }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            className={"hover"}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 20 },
                                },
                            }}
                            // onCellClick={(
                            //   params: GridCellParams,
                            //   event: MuiEvent<React.MouseEvent>
                            // ) => {
                            //   event.defaultMuiPrevented = true;
                            // }}
                            pageSizeOptions={[20, 50, 100]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={
                                handleChangeCellSelection
                            }
                            slots={{
                                toolbar: toolbarDatagridOptions,
                            }}
                            sx={{
                                '@media print': {
                                    zoom: '75%',
                                }
                            }}
                            onRowClick={(params, event) =>
                                handleRowClick(params.row, event)
                            }
                        />
                    </Data>
                </Content>
            </Holder>
        </>
    );
}
