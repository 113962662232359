import React from "react";
import styled from "styled-components";

import { useImpersonation } from "../global/impersonation";

const Title = styled.div`
    width: 100%;
    height: 32px;
    background-color: rgba(222, 96, 97, 1);
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    left: 0px;
    top: 0px;
`;

const Text = styled.h3`
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #181122;
    margin: 0;
`;

const Button = styled.button`
    margin: 0 auto;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    font-weight: 600;
    color: #ffffff;
    margin-left: -10px;
    font-size: 12px;
`;

const ImpersonationTitle = () => {
    const { isImpersonate, stopImpersonation } = useImpersonation();

    if (!isImpersonate) {
        return null;
    }

    return (
        <Title>
            <Text>
                VOCÊ ESTA IMPERSONADO. DESEJA RETORNAR PARA SUA EMPRESA?
            </Text>
            <Button onClick={stopImpersonation}>CLIQUE AQUI</Button>
        </Title>
    );
};

export default ImpersonationTitle;
