import { Routes, Route } from "react-router-dom";
import React from "react";

import "./styles/main.css";

// import Dashboard from "./pages/dashboard.js";
import Login from "./pages/login.js";
import Refunds from "./pages/refunds";
import Wrapper from "./pages/wrapper";
import ResetPassword from "./pages/reset";
import ResetPasswordCommit from "./pages/reset.commit";
import Users from "./pages/users";
import Credits from "./pages/credits";
import Settings from "./pages/settings";
import Impersonate from "./pages/impersonate.js";
import ImpersonationTitle from './components/title_impersonate.js';

import Store from "./global/global";
import { ImpersonationProvider } from "./global/impersonation.js";

function App() {
  return (
    <ImpersonationProvider>
      <Store>
        <ImpersonationTitle />
        <Routes>
          <Route path="/refunds" element={<Wrapper screen={<Refunds />} />} />
          <Route path="/users" element={<Wrapper screen={<Users />} />} />
          <Route path="/credits" element={<Wrapper screen={<Credits />} />} />
          <Route path="/settings" element={<Wrapper screen={<Settings />} />} />
          <Route path="/impersonate" element={<Wrapper screen={<Impersonate />} />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/reset/:token" element={<ResetPasswordCommit />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Wrapper screen={<Refunds />} />} />
        </Routes>
      </Store>
    </ImpersonationProvider>
    
  );
}

export default App;
