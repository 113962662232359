import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import BasicTitle from "../components/title";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import Loader from "./loader";

import Api from "../libs/api";
import Storage from "../libs/storage";

const ModalCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 688px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  overflow: visible;
  border: 1px solid rgb(144, 144, 144);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalClose = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const ModalView = styled.div`
  margin-bottom: 16px;
`;

const ModalLineView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: right;
  justify-content: flex-end;
`;

const Clear = styled.div`
  clear: both;
`;

export default function EditUser({
  user,
  title,
  open,
  close,
  banks,
  categories,
  projects,
  getUsers,
  impersonateCompanyId,
}) {
  let api = Api();
  let storage = Storage();

  const [loading, setLoading] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  // eslint-disable-next-line no-unused-vars
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selectedProjectsInput, setSelectedProjectsInput] = useState([]);
  const [selectedAdminProjectsInput, setSelectedAdminProjectsInput] = useState(
    []
  );
  const [inputValueProject, setInputValueProject] = useState("");

  const selectedProjectObjects = projects.filter((project) =>
    selectedProjectsInput.includes(project.id)
  );

  const selectedAdminProjectObjects = projects.filter((project) =>
    selectedAdminProjectsInput.includes(project.id)
  );

  const getProjectName = (id) => {
    for (let index = 0; index < projects.length; index++) {
      const _project = projects[index];
      if (id === _project.id) {
        return _project.name;
      }
    }
    return "";
  };

  const handleChangeProjects = (event, newValue) => {
    if (newValue && newValue[newValue.length - 1]?.id === "allProjects") {
      const allProjects = projects
        .filter((project) => !project.disabled)
        .map((project) => project.id);
      setSelectedProjectsInput(allProjects);
    } else {
      setSelectedProjectsInput(newValue.map((project) => project.id));
    }
  };

  const handleChangeAdminProjects = (event, newValue) => {
    if (newValue && newValue[newValue.length - 1]?.id === "allProjects") {
      const allProjects = projects
        .filter((project) => !project.disabled)
        .map((project) => project.id);
      setSelectedAdminProjectsInput(allProjects);
    } else {
      setSelectedAdminProjectsInput(newValue.map((project) => project.id));
    }
  };

  const handleRemoveProject = (projectToRemove) => {
    setSelectedProjectsInput((prevSelected) =>
      prevSelected.filter((project) => project !== projectToRemove.id)
    );
  };

  const handleInputChangeProject = (event, newInputValue) => {
    setInputValueProject(newInputValue);
  };

  const [selectedCategoriesInput, setSelectedCategoriesInput] = useState([]);
  const [selectedAdminCategoriesInput, setSelectedAdminCategoriesInput] =
    useState([]);
  const [inputValueCategory, setInputValueCategory] = useState("");

  const selectedCategoryObjects = categories.filter((categorie) =>
    selectedCategoriesInput.includes(categorie.id)
  );

  const selectedAdminCategoryObjects = categories.filter((categorie) =>
    selectedAdminCategoriesInput.includes(categorie.id)
  );

  const getCategoryName = (id) => {
    for (let index = 0; index < categories.length; index++) {
      const _category = categories[index];
      if (id === _category.id) {
        return _category.name;
      }
    }
    return "";
  };

  const handleInputChangeCategory = (event, newInputValue) => {
    setInputValueCategory(newInputValue);
  };

  const handleChangeCategories = (event, newValue) => {
    if (newValue && newValue[newValue.length - 1]?.id === "allCategories") {
      const allCategories = categories
        .filter((category) => !category.disabled)
        .map((category) => category.id);
      setSelectedCategoriesInput(allCategories);
    } else {
      setSelectedCategoriesInput(newValue.map((category) => category.id));
    }
  };

  const handleChangeAdminCategories = (event, newValue) => {
    if (newValue && newValue[newValue.length - 1]?.id === "allCategories") {
      const allCategories = categories
        .filter((category) => !category.disabled)
        .map((category) => category.id);
      setSelectedAdminCategoriesInput(allCategories);
    } else {
      setSelectedAdminCategoriesInput(newValue.map((category) => category.id));
    }
  };

  const handleRemoveCategory = (categoryToRemove) => {
    setSelectedCategoriesInput((prevSelected) =>
      prevSelected.filter((category) => category !== categoryToRemove.id)
    );
  };

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [role, setRole] = useState(user.role);
  const [hiringType, setHiringType] = useState(user.userHiringType);
  const [document, setDocument] = useState(user.cpf ? user.cpf : user.cnpj);
  const [bank, setBank] = useState(user.bankCode);
  const [agency, setAgency] = useState(user.bankAgency);
  const [account, setAccount] = useState(user.bankAccount);
  const [pixType, setPixType] = useState(user.pixType);
  const [pixValue, setPixValue] = useState(user.pix);
  const [valueTab, setValueTab] = React.useState("1");

  const [companySettings, setCompanySettings] = useState(
    user.userPermissions.companySettings
  );
  const [userSettings, setUserSettings] = useState(user.userPermissions.users);
  const [plansSettings, setPlansSettings] = useState(
    user.userPermissions.plans
  );
  const [creditsSettings, setCreditsSettings] = useState(
    user.userPermissions.credits
  );
  const [refundPay, setRefundPay] = useState(user.userPermissions.refundPay);
  const [refundApprove, setRefundApprove] = useState(
    user.userPermissions.refundApprove
  );

  useEffect(() => {
    let _categories = [];
    for (let index = 0; index < user?.restrictedCategories?.length; index++) {
      const _category = user.restrictedCategories[index];
      _categories.push(btoa(`CategoryNodeView:${_category.id}`));
    }
    const categoryIds = _categories.map((id) => id);

    let filteredCategories = categories.filter(
      (category) => !categoryIds.includes(category.id) && !category.disabled
    );

    if (filteredCategories.length === 0) {
      filteredCategories = categories.filter((category) => !category.disabled);
    }

    const filteredCategoryIds = filteredCategories.map((category) => category.id);
    setSelectedCategoriesInput(filteredCategoryIds);

    _categories = [];
    for (
      let index = 0;
      index < user?.userPermissions?.categories?.length;
      index++
    ) {
      const _categoryId = user.userPermissions.categories[index];
      _categories.push(btoa(`CategoryNodeView:${_categoryId}`));
    }

    let filteredAdminCategories;
    if (user?.userPermissions?.categories?.length === 0) {
      filteredAdminCategories = categories.filter(
        (category) => !category.disabled
      );
    } else {
      filteredAdminCategories = categories.filter((category) => {
        return _categories.includes(category.id) && !category.disabled;
      });
    }

    const filteredAdminCategoryIds = filteredAdminCategories.map(
      (category) => category.id
    );

    setSelectedAdminCategoriesInput(filteredAdminCategoryIds);

    let _projects = [];
    for (let index = 0; index < user?.restrictedProjects?.length; index++) {
      const _project = user.restrictedProjects[index];
      _projects.push(btoa(`ProjectNodeView:${_project.id}`));
    }

    const projectIds = _projects.map((id) => id);

    let filteredProjects = projects.filter(
      (project) => !projectIds.includes(project.id) && !project.disabled
    );

    if (filteredProjects.length === 0) {
      filteredProjects = projects.filter((project) => !project.disabled);
    }

    const filteredProjectIds = filteredProjects.map((project) => project.id);
    setSelectedProjectsInput(filteredProjectIds);

    _projects = [];
    for (let index = 0; index < user?.userPermissions?.projects?.length; index++) {
      const _projectId = user.userPermissions.projects[index];
      _projects.push(btoa(`ProjectNodeView:${_projectId}`));
    }

    let filteredAdminProjects;
    if (user?.userPermissions?.projects?.length === 0) {
      filteredAdminProjects = projects.filter((project) => !project.disabled);
    } else {
      filteredAdminProjects = projects.filter((project) => {
        return _projects.includes(project.id) && !project.disabled;
      });
    }

    const filteredAdminProjectsIds = filteredAdminProjects.map(
      (project) => project.id
    );

    setSelectedAdminProjectsInput(filteredAdminProjectsIds);

    setName(user.name);
    setEmail(user.email);
    setRole(user.role);
    setHiringType(user.userHiringType);
    setDocument(user.cpf ? user.cpf : user.cnpj);
    setBank(user.bankCode);
    setAgency(user.bankAgency);
    setAccount(user.bankAccount);
    setPixType(user.pixType);
    setPixValue(user.pix);

    setCompanySettings(user.userPermissions.companySettings);
    setUserSettings(user.userPermissions.users);
    setCreditsSettings(user.userPermissions.credits);
    setPlansSettings(user.userPermissions.plans);
    setRefundPay(user.userPermissions.refundPay);
    setRefundApprove(user.userPermissions.refundApprove);

    setValueTab("1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const saveUser = async () => {
    if (name) {
      setLoading(true);
      const data = await storage.getItem("data");

      const selectedProjects =
        selectedProjectsInput.length === 0
          ? projects.map((project) => project.id)
          : projects
              .filter((project) => !selectedProjectsInput.includes(project.id))
              .map((project) => project.id);

      const selectedCategories =
        selectedCategoriesInput.length === 0
          ? categories.map((category) => category.id)
          : categories
              .filter(
                (category) => !selectedCategoriesInput.includes(category.id)
              )
              .map((category) => category.id);

      await api.saveAdminUser(
        data.token,
        name,
        email,
        role,
        hiringType,
        document,
        bank,
        agency,
        account,
        pixType,
        pixValue,
        companySettings,
        userSettings,
        creditsSettings,
        plansSettings,
        refundPay,
        refundApprove,
        selectedCategories,
        selectedProjects,
        selectedAdminCategoriesInput,
        selectedAdminProjectsInput
      );

      setLoading(false);
      getUsers("all", "all");
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalCenter style={{ width: 1280, maxWidth: "100%" }}>
        <ModalClose
          src="/img/modal_close.svg"
          onClick={() => {
            close();
          }}
        />
        {loading && <Loader inside={true} />}
        <BasicTitle title={title} margin={32} />
        <Clear />
        <ModalView>
          <>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={valueTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Dados Pessoais" value="1" />
                    <Tab label="Permissōes" value="3" />
                    {user.role > 0 && <Tab label="Admin" value="4" />}
                  </TabList>
                </Box>
                <TabPanel value="1" style={{ padding: "24px 0px" }}>
                  <ModalLineView>
                    <TextField
                      id="outlined-basic"
                      label="Nome"
                      value={name}
                      variant="outlined"
                      style={{ width: "90%", marginRight: 24 }}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                    <FormControl
                      style={{
                        width: "90%",
                        marginRight: 24,
                      }}
                    >
                      <InputLabel>Perfil</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Perfil"
                        value={role}
                        defaultValue={role}
                        onChange={(event) => {
                          const newRole = event.target.value;
                          setRole(newRole);
                          if (role === 0 && (newRole === 1 || newRole === 2)) {
                            setCompanySettings(true);
                            setUserSettings(true);
                            setCreditsSettings(true);
                            setRefundPay(true);
                            setRefundApprove(true);
                          }
                        }}
                      >
                        <MenuItem key={0} value={0}>
                          Usuário
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          Administrador
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Proprietário
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      value={email}
                      disabled
                      variant="outlined"
                      style={{ width: "90%", marginRight: 24 }}
                    />
                  </ModalLineView>
                </TabPanel>
                <TabPanel value="3" style={{ padding: "24px 0px" }}>
                  <ModalLineView>
                    <FormControl style={{ width: "100%", marginRight: 24 }}>
                      <Autocomplete
                        style={{
                          maxHeight: "250px",
                          overflowY: "auto",
                          paddingTop: "5px",
                        }}
                        multiple
                        id="autocomplete-projects"
                        options={[
                          { id: "allProjects", name: "SELECIONAR TODOS" },
                          ...projects.filter((project) => !project.disabled),
                        ]}
                        value={selectedProjectObjects}
                        onChange={handleChangeProjects}
                        inputValue={inputValueProject}
                        onInputChange={handleInputChangeProject}
                        getOptionLabel={(project) => {
                          if (project.id === "allProjects") {
                            return (
                              <span
                                style={{ color: "#419392", fontWeight: 500 }}
                              >
                                {project.name}
                              </span>
                            );
                          } else {
                            return project.name;
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Centro de Custo"
                            variant="outlined"
                          />
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={option.id}
                              label={getProjectName(option.id)}
                              onDelete={() => handleRemoveProject(option)}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        freeSolo
                        ListboxProps={{
                          style: {
                            overflowY: "auto",
                            maxHeight: "220px",
                          },
                        }}
                      />
                    </FormControl>
                    <FormControl style={{ width: "100%" }}>
                      <Autocomplete
                        style={{
                          maxHeight: "250px",
                          overflowY: "auto",
                          paddingTop: "5px",
                        }}
                        multiple
                        id="autocomplete-categories"
                        options={[
                          { id: "allCategories", name: "SELECIONAR TODOS" },
                          ...categories.filter(
                            (category) => !category.disabled
                          ),
                        ]}
                        value={selectedCategoryObjects}
                        onChange={handleChangeCategories}
                        inputValue={inputValueCategory}
                        onInputChange={handleInputChangeCategory}
                        getOptionLabel={(category) => {
                          if (category.id === "allCategories") {
                            return (
                              <span
                                style={{ color: "#419392", fontWeight: 500 }}
                              >
                                {category.name}
                              </span>
                            );
                          } else {
                            return category.name;
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Categorias"
                            variant="outlined"
                          />
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={option.id}
                              label={getCategoryName(option.id)}
                              onDelete={() => handleRemoveCategory(option)}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        freeSolo
                        ListboxProps={{
                          style: {
                            overflowY: "auto",
                            maxHeight: "220px",
                          },
                        }}
                      />
                    </FormControl>
                  </ModalLineView>
                </TabPanel>
                {user.role > 0 && (
                  <TabPanel value="4" style={{ padding: "24px 0px" }}>
                    <ModalLineView>
                      <FormGroup style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={companySettings}
                              onChange={(event) => {
                                setCompanySettings(event.target.checked);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Configurações Empresa"
                        />
                      </FormGroup>
                      <FormGroup style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={userSettings}
                              onChange={(event) => {
                                setUserSettings(event.target.checked);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Configurações Usuários"
                        />
                      </FormGroup>
                      <FormGroup style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={creditsSettings}
                              onChange={(event) => {
                                setCreditsSettings(event.target.checked);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Gerenciar Créditos"
                        />
                      </FormGroup>
                    </ModalLineView>
                    <ModalLineView>
                      <FormGroup style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={refundApprove}
                              onChange={(event) => {
                                setRefundApprove(event.target.checked);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Aprovar Reembolsos"
                        />
                      </FormGroup>
                      <FormGroup style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={refundPay}
                              onChange={(event) => {
                                setRefundPay(event.target.checked);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Pagar Reembolsos"
                        />
                      </FormGroup>
                      <FormGroup style={{ width: "100%" }} />
                      {/* <FormGroup style={{ width: "33%" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={plansSettings}
                              onChange={(event) => {
                                setPlansSettings(event.target.checked);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Gerenciar Planos"
                        />
                      </FormGroup> */}
                    </ModalLineView>
                    <ModalLineView>
                      <FormControl style={{ width: "100%", marginRight: 24 }}>
                        <Autocomplete
                          style={{
                            maxHeight: "250px",
                            overflowY: "auto",
                            paddingTop: "5px",
                          }}
                          multiple
                          id="autocomplete-projects"
                          options={[
                            { id: "allProjects", name: "SELECIONAR TODOS" },
                            ...projects
                            .filter((project) => !project.disabled)
                            .sort((a, b) => a.name.localeCompare(b.name)),
                          ]}
                          value={selectedAdminProjectObjects}
                          onChange={handleChangeAdminProjects}
                          inputValue={inputValueProject}
                          onInputChange={handleInputChangeProject}
                          getOptionLabel={(project) => {
                            if (project.id === "allProjects") {
                              return (
                                <span
                                  style={{ color: "#419392", fontWeight: 500 }}
                                >
                                  {project.name}
                                </span>
                              );
                            } else {
                              return project.name;
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Centro de Custo Gerenciáveis"
                              variant="outlined"
                            />
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                key={option.id}
                                label={getProjectName(option.id)}
                                onDelete={() => handleRemoveProject(option)}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          freeSolo
                          ListboxProps={{
                            style: {
                              overflowY: "auto",
                              maxHeight: "220px",
                            },
                          }}
                        />
                      </FormControl>
                      <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                          style={{
                            maxHeight: "250px",
                            overflowY: "auto",
                            paddingTop: "5px",
                          }}
                          multiple
                          id="autocomplete-categories"
                          options={[
                            { id: "allCategories", name: "SELECIONAR TODOS" },
                            ...categories
                            .filter((category) => !category.disabled)
                            .sort((a, b) => a.name.localeCompare(b.name)),
                          ]}
                          value={selectedAdminCategoryObjects}
                          onChange={handleChangeAdminCategories}
                          inputValue={inputValueCategory}
                          onInputChange={handleInputChangeCategory}
                          getOptionLabel={(category) => {
                            if (category.id === "allCategories") {
                              return (
                                <span
                                  style={{ color: "#419392", fontWeight: 500 }}
                                >
                                  {category.name}
                                </span>
                              );
                            } else {
                              return category.name;
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Categorias Gerenciáveis"
                              variant="outlined"
                            />
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                key={option.id}
                                label={getCategoryName(option.id)}
                                onDelete={() => handleRemoveCategory(option)}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          freeSolo
                          ListboxProps={{
                            style: {
                              overflowY: "auto",
                              maxHeight: "220px",
                            },
                          }}
                        />
                      </FormControl>
                    </ModalLineView>
                  </TabPanel>
                )}
              </TabContext>
            </Box>
          </>
        </ModalView>
        <Clear />
        <ModalFooter>
          {impersonateCompanyId === null ? (
            <Button
            style={{
              width: 150,
              fontSize: 12,
              padding: "8px 0px",
            }}
            variant={"contained"}
            color={"primary"}
            onClick={saveUser}
          >
            SALVAR
          </Button>
          ) : (
            <Button
              style={{
                width: 150,
                fontSize: 12,
                padding: "8px 0px",
              }}
              variant={"outlined"}
              color={"primary"}
            >
              Salvar
            </Button>
          )}

        </ModalFooter>
      </ModalCenter>
    </Modal>
  );
}
