export default function Storage() {
  const getItem = async (item) => {
    item = JSON.parse(localStorage.getItem(item));
    // console.log("got", item);
    return item;
  };

  const saveItem = async (item, data) => {
    localStorage.setItem(item, JSON.stringify(data));
    // console.log("saved", item, data);
    return true;
  };

  const removeItem = async (item) => {
    localStorage.removeItem(item);
    // console.log("deleted", item);
    return true;
  };

  return {
    getItem,
    saveItem,
    removeItem,
  };
}
