import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Content from "../components/content";
import Loader from "../components/loader";

import { useImpersonation } from "../global/impersonation";

import { Button, TextField, Box, Collapse, CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import Api from "../libs/api";
import Storage from "../libs/storage";
import Helper from "../libs/helper";

import Edit from "../components/edit_cat_proj";
import AddCategory from "../components/add_category";
import AddProject from "../components/add_project";

const Holder = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
`;

const Grid = styled.div`
    margin-top: 20px;
`;

const ImageIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
    color: #57c4c2;
`;

const SubTitle = styled.div`
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #181122;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 5px;
`;

const Field = styled.div`
    display: flex;
    height: 100%;
`;
const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;

const CenteredFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
`;

const View = styled.div`
    display: flex;
    flex-direction: row;
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    width: 35%;
    margin-top: 15px;
    margin-bottom: 15px;
`;

const ButtonAdd = styled(Button)`
    && {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #181122;
        text-transform: uppercase;
        margin-top: 15px;
        padding-left: 0;
    }
`;

const TextApi = styled.div`
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #181122;
    width: 100%;
    margin-bottom: 5px;
`;

export default function Settings({ refreshProfile }) {
    let api = Api();
    let storage = Storage();
    let helper = Helper();
    const { impersonateCompanyId } = useImpersonation();

    const getProfile = async () => {
        const data = await storage.getItem("data");
        if (!data) return;
        setToken(data.token);
        let response = await api.getProfile(data.token, impersonateCompanyId);
        setProfile(response.data.profile);
    };

    const getProjects = async () => {
        const data = await storage.getItem("data");
        if (!data) return;
        let response = await api.getProjects(data.token, impersonateCompanyId);
        setProjects(response.data.allProjects.edges);
    };

    const getCategories = async () => {
        const data = await storage.getItem("data");
        if (!data) return;
        let response = await api.getCategories(
            data.token,
            impersonateCompanyId
        );
        setCategories(response.data.allCategories.edges);
    };

    let [profile, setProfile] = useState();
    let [token, setToken] = useState(null);
    let [projects, setProjects] = useState([]);
    let [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(false);

    const closeAllDropdowns = () => {
        setIsOpen1(false);
        setIsOpen2(false);
        setIsOpen3(false);
        setIsOpen4(false);
        setIsOpen5(false);
        setIsOpen6(false);
    };

    const toggleDropdown1 = () => {
        closeAllDropdowns();
        setIsOpen1(!isOpen1);
    };

    const toggleDropdown2 = () => {
        closeAllDropdowns();
        setIsOpen2(!isOpen2);
    };

    const toggleDropdown3 = () => {
        closeAllDropdowns();
        setIsOpen3(!isOpen3);
    };

    const toggleDropdown4 = () => {
        closeAllDropdowns();
        setIsOpen4(!isOpen4);
    };

    const toggleDropdown5 = () => {
        closeAllDropdowns();
        setIsOpen5(!isOpen5);
    };

    const toggleDropdown6 = () => {
        closeAllDropdowns();
        setIsOpen6(!isOpen6);
    };

    // useEffect(() => {
    //     const fetchData = async () => {
    //         setLoading(true);
    //         const data = await storage.getItem("data");
    //         const [profileResponse, projectsResponse, categoriesResponse] =
    //             await Promise.all([
    //                 api.getProfile(data.token, impersonateCompanyId),
    //                 api.getProjects(data.token, impersonateCompanyId),
    //                 api.getCategories(data.token, impersonateCompanyId),
    //             ]);

    //         setProfile(profileResponse.data.profile);
    //         setProjects(projectsResponse.data.allProjects.edges);
    //         setCategories(categoriesResponse.data.allCategories.edges);

    //         setLoading(false);
    //     };
    //     fetchData();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    let [phone, setPhone] = useState("");
    let [name, setName] = useState("");
    let [mileageCost, setMileageCost] = useState();
    let [mileageRefundsEnabled, setMileageRefundsEnabled] = useState("");
    let [allowsCorporateCard, setAllowsCorporateCard] = useState("");
    let [company, setCompany] = useState("");
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAddCategory, setOpenAddCategory] = useState(false);
    const [openAddProject, setOpenAddProject] = useState(false);
    const [selectChanged, setSelectChanged] = useState(false);

    const handleCloseModal = () => {
        setSelectedProject(null);
        setselectedCategory(null);
        setOpenEdit(false);
        setOpenAddCategory(false);
        setOpenAddProject(false);
    };

    const handleOpenEdit = () => {
        setOpenEdit(true);
        setOpenAddCategory(false);
        setOpenAddProject(false);
    };

    useEffect(() => {
        if (!openEdit || !openAddCategory || !openAddProject) {
            getProfile();
            getCategories();
            getProjects();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openEdit, openAddCategory, openAddProject]);

    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedCategory, setselectedCategory] = useState(null);

    useEffect(() => {
        if (profile) {
            setName(profile?.profile?.company?.name);
            setPhone(profile?.profile?.company?.phone);
            setCompany(
                parseInt(atob(profile?.profile?.company?.id).split(":")[1])
            );
            setMileageCost((profile?.profile?.company?.mileageCost.toFixed(2).replace(".", ",")));
            setMileageRefundsEnabled(
                profile?.profile?.company?.acceptMileage ? "True" : "False"
            );
            setAllowsCorporateCard(
                profile?.profile?.company?.allowCorporate ? "True" : "False"
            );
        }
    }, [profile]);

    const [impersonatedCompany, setImpersonatedCompany] = useState({});

    useEffect(() => {
        if (impersonatedCompany) {
            setPhone(impersonatedCompany.phone);
            setName(impersonatedCompany.name);
            const rawMileageCost = impersonatedCompany.mileageCost;
            const formattedMileageCost = !isNaN(parseFloat(rawMileageCost)) 
            ? parseFloat(rawMileageCost).toFixed(2).replace(".", ",") 
            : "0,00";
            setMileageCost(formattedMileageCost);
            setMileageRefundsEnabled(impersonatedCompany.mileageRefundsEnabled ? "True" : "False");
            setAllowsCorporateCard(impersonatedCompany.allowsCorporateCard ? "True" : "False");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [impersonatedCompany]);

    useEffect(() => {
        if (
            name !== profile?.profile?.company?.name ||
            phone !== profile?.profile?.company?.phone ||
            mileageCost !== profile?.profile?.company?.mileageCost
        ) {
            setButtonEnabled(true);
        } else {
            setButtonEnabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, phone, mileageCost]);

    const editCompany = async () => {
        setLoading(true);

        let formattedMileage = parseFloat(mileageCost.replace("R$ ", "").replace(",", "."));

        await api.editCompany(
            token,
            name,
            phone,
            allowsCorporateCard,
            mileageRefundsEnabled,
            formattedMileage,
            company,
            impersonateCompanyId
        );

        if (refreshProfile) {
            refreshProfile();
        }
        setLoading(false);
    };

    useEffect(() => {
        if (selectChanged) {
            editCompany(
                name,
                phone,
                allowsCorporateCard,
                mileageRefundsEnabled,
                mileageCost,
                company
            );
            setSelectChanged(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowsCorporateCard, mileageRefundsEnabled, selectChanged]);

    const defaultMaskOptions = {
        prefix: "R$ ",
        suffix: "",
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: "",
        allowDecimal: true,
        decimalSymbol: ",",
        decimalLimit: 2,
        integerLimit: 7,
        allowNegative: false,
        allowLeadingZeroes: false,
    };

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
    });

    const fetchCompanyData = async () => {
                if (!impersonateCompanyId) return;
    
                const data = await storage.getItem("data");
                const response = await api.getImpersonatedCompany(data.token, impersonateCompanyId);

                if (response.data.allCompanies.edges) {
                    setImpersonatedCompany(response.data.allCompanies.edges[0].node);
                }
            };

    useEffect(() => {
        fetchCompanyData();
        if (impersonateCompanyId === null ) {
            getProfile();}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [impersonateCompanyId]);

    return (
        <>
            {loading && <Loader />}
            <Edit
                type={"edit"}
                open={openEdit}
                close={handleCloseModal}
                project={selectedProject}
                categorie={selectedCategory}
                impersonate={impersonateCompanyId}
            />
            <AddCategory
                type={"add"}
                open={openAddCategory}
                close={handleCloseModal}
                profile={selectedProfile}
                impersonate={impersonateCompanyId}
            />
            <AddProject
                type={"add"}
                open={openAddProject}
                close={handleCloseModal}
                profile={selectedProfile}
                impersonate={impersonateCompanyId}

            />
            <Holder>
                <Content title={"Configurações"}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-evenly"
                        alignItems="stretch"
                    >
                        <Button
                            onClick={toggleDropdown1}
                            variant="outlined"
                            style={{
                                border: "none",
                                marginTop: 15,
                                padding: 0,
                            }}
                        >
                            {isOpen1 ? (
                                <ImageIcon src="/img/arrow_up.svg" alt="" />
                            ) : (
                                <ImageIcon src="/img/arrow_down.svg" alt="" />
                            )}
                            DADOS DA EMPRESA
                        </Button>
                        <Box
                            style={{
                                paddingBottom: 20,
                                border: "none",
                                borderBottom: "1px solid #E6E6E6",
                                width: "100%",
                                paddingTop: 10,
                                paddingLeft: 10,
                            }}
                        >
                            <Collapse in={isOpen1}>
                                <Field>
                                    <FieldContainer>
                                        <SubTitle>EMPRESA</SubTitle>
                                        <TextField
                                            id="outlined-basic"
                                            value={name}
                                            variant="outlined"
                                            style={{ borderRadius: 5 }}
                                            onChange={(event) => {
                                                if (!impersonateCompanyId) {
                                                    setName(event.target.value);
                                                }
                                            }}
                                            disabled={!!impersonateCompanyId} 
                                        />
                                    </FieldContainer>
                                    <FieldContainer>
                                        <SubTitle>TELEFONE</SubTitle>
                                        <TextField
                                            id="outlined-basic"
                                            value={helper.formatPhoneNumber(phone
                                            )}
                                            variant="outlined"
                                            style={{ borderRadius: 5 }}
                                            onChange={(event) => {
                                                if (!impersonateCompanyId) {
                                                    setPhone(helper.formatPhoneNumber(event.target.value));
                                                }
                                            }}
                                            disabled={!!impersonateCompanyId}
                                        />
                                    </FieldContainer>
                                    <CenteredFieldContainer>
                                        <Button
                                            style={{
                                                fontSize: 12,
                                                opacity: 1,
                                                marginTop: 5,
                                                height: 50,
                                                padding: 20,
                                                // eslint-disable-next-line no-dupe-keys
                                                opacity: buttonEnabled
                                                    ? 1
                                                    : 0.4,
                                            }}
                                            variant={"outlined"}
                                            color={
                                                buttonEnabled
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            onClick={() =>
                                                editCompany(name, phone)
                                            }
                                            disabled={!!impersonateCompanyId}
                                        >
                                            SALVAR
                                        </Button>
                                    </CenteredFieldContainer>
                                </Field>
                            </Collapse>
                        </Box>
                        <Button
                            onClick={toggleDropdown2}
                            variant="outlined"
                            style={{
                                border: "none",
                                marginTop: 15,
                                padding: 0,
                            }}
                        >
                            {isOpen2 ? (
                                <ImageIcon src="/img/arrow_up.svg" alt="" />
                            ) : (
                                <ImageIcon src="/img/arrow_down.svg" alt="" />
                            )}
                            CARTÃO CORPORATIVO
                        </Button>
                        <Box
                            style={{
                                paddingBottom: 20,
                                border: "none",
                                borderBottom: "1px solid #E6E6E6",
                                width: "100%",
                                paddingTop: 10,
                                paddingLeft: 10,
                            }}
                        >
                            <Collapse in={isOpen2}>
                                <Field>
                                    <FieldContainer>
                                        <SubTitle>ESCOLHER OPÇÃO</SubTitle>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{
                                                width: 180,
                                            }}
                                            value={allowsCorporateCard}
                                            onChange={(event) => {
                                                setAllowsCorporateCard(
                                                    event.target.value
                                                );
                                                setSelectChanged(true);
                                            }}
                                            disabled={!!impersonateCompanyId}
                                        >
                                            <MenuItem key={1} value="True">
                                                ATIVO
                                            </MenuItem>
                                            <MenuItem key={2} value="False">
                                                INATIVO
                                            </MenuItem>
                                        </Select>
                                    </FieldContainer>
                                </Field>
                            </Collapse>
                        </Box>
                        <Button
                            onClick={toggleDropdown3}
                            variant="outlined"
                            style={{
                                border: "none",
                                marginTop: 15,
                                padding: 0,
                            }}
                        >
                            {isOpen3 ? (
                                <ImageIcon src="/img/arrow_up.svg" alt="" />
                            ) : (
                                <ImageIcon src="/img/arrow_down.svg" alt="" />
                            )}
                            QUILOMETRAGEM
                        </Button>
                        <Box
                            style={{
                                paddingBottom: 20,
                                border: "none",
                                borderBottom: "1px solid #E6E6E6",
                                width: "100%",
                                paddingTop: 10,
                                paddingLeft: 10,
                            }}
                        >
                            <Collapse in={isOpen3}>
                                <FieldContainer>
                                    <SubTitle style={{ marginTop: 20 }}>
                                        ESCOLHER OPÇÃO
                                    </SubTitle>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{
                                            width: 180,
                                        }}
                                        value={mileageRefundsEnabled}
                                        onChange={(event) => {
                                            setMileageRefundsEnabled(
                                                event.target.value
                                            );
                                            setSelectChanged(true);
                                        }}
                                        disabled={!!impersonateCompanyId}
                                    >
                                        <MenuItem value="True">ATIVO</MenuItem>
                                        <MenuItem value="False">
                                            INATIVO
                                        </MenuItem>
                                    </Select>
                                </FieldContainer>
                                <Field style={{ marginTop: 20 }}>
                                    <FieldContainer>
                                        <SubTitle>VALOR POR KM</SubTitle>
                                        <MaskedInput
                                            mask={currencyMask}
                                            placeholder="R$ 0,00"
                                            style={{
                                                fontSize: 16,
                                                padding: 14,
                                                border: "1px solid #C1C4C8",
                                                borderRadius: 4,
                                                marginRight: 16,
                                                width: 200,
                                                fontWeight: 400,
                                                color: impersonateCompanyId === null ? "black" : "rgba(0, 0, 0, 0.38)",
                                                backgroundColor: "rgba(0, 0, 0, 0)",
                                            }}
                                            value={`R$ ${mileageCost}`}
                                            onChange={(event) => {
                                                setMileageCost(event.target.value);
                                            }}
                                            disabled={!!impersonateCompanyId}
                                        />
                                    </FieldContainer>
                                    <CenteredFieldContainer>
                                        <Button
                                            style={{
                                                fontSize: 12,
                                                opacity: 1,
                                                marginTop: 5,
                                                height: 50,
                                                padding: 20,
                                                // eslint-disable-next-line no-dupe-keys
                                                opacity: buttonEnabled
                                                    ? 1
                                                    : 0.4,
                                            }}
                                            variant={"outlined"}
                                            color={
                                                buttonEnabled
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            onClick={() =>
                                                editCompany(mileageCost)
                                            }
                                            disabled={!!impersonateCompanyId}
                                        >
                                            SALVAR
                                        </Button>
                                    </CenteredFieldContainer>
                                </Field>
                            </Collapse>
                        </Box>

                        <Button
                            onClick={toggleDropdown4}
                            variant="outlined"
                            style={{
                                border: "none",
                                marginTop: 15,
                                padding: 0,
                            }}
                        >
                            {isOpen4 ? (
                                <ImageIcon src="/img/arrow_up.svg" alt="" />
                            ) : (
                                <ImageIcon src="/img/arrow_down.svg" alt="" />
                            )}
                            CENTRO DE CUSTO
                        </Button>
                        <Box
                            style={{
                                paddingBottom: 20,
                                border: "none",
                                borderBottom: "1px solid #E6E6E6",
                                width: "100%",
                                paddingTop: 10,
                                paddingLeft: 10,
                            }}
                        >
                            <Collapse in={isOpen4}>
                                <FieldContainer style={{ width: "45%" }}>
                                    <View
                                        style={{
                                            borderBottom: "1px solid #E6E6E6",
                                            width: "100%",
                                        }}
                                    >
                                        {impersonateCompanyId === null ? (
                                            <ButtonAdd
                                            variant="text"
                                            onClick={() => {
                                                setOpenAddProject(true);
                                                setSelectedProfile(profile);
                                            }}
                                            style={{
                                                marginBottom: 15,
                                                marginRight: 15,
                                            }}
                                            >
                                                ADICIONAR CENTRO DE CUSTO
                                            </ButtonAdd>
                                        ) : (
                                            <ButtonAdd
                                                variant="text"
                                                style={{
                                                    marginBottom: 15,
                                                    marginRight: 15,
                                                    color: "#D3D3D3",
                                                    cursor: "not-allowed",
                                                }}
                                            >
                                                ADICIONAR CENTRO DE CUSTO
                                            </ButtonAdd>
                                        )}
                                    </View>
                                    {loading ? (
                                        <View
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "20px",
                                            }}
                                        >
                                            <CircularProgress size={24} />
                                        </View>
                                    ) : (
                                        projects.map((project) => (
                                            <View
                                                key={project.node.id}
                                                style={{
                                                    borderBottom: "1px solid #E6E6E6",
                                                }}
                                            >
                                                <Item>{project.node.name}</Item>
                                                <Item>
                                                    {project.node.restrictedProject ? "RESTRITO" : "TODOS"}
                                                </Item>
                                                <Item
                                                    style={{
                                                        alignItems: "flex-end",
                                                        marginRight: 25,
                                                    }}
                                                >
                                                    {project.node.disabled ? "INATIVO" : "ATIVO"}
                                                </Item>
                                                {impersonateCompanyId === null ? (
                                                    <Button
                                                        onClick={() => {
                                                            handleOpenEdit(setSelectedProject(project));
                                                        }}
                                                        variant="text"
                                                        startIcon={
                                                            <img src="/img/pen_edit.svg" alt="Ícone" />
                                                        }
                                                    />
                                            ) :(
                                                <Button
                                                    variant="text"
                                                    startIcon={
                                                        <img src="/img/pen_edit.svg" alt="Ícone" />
                                                    }
                                                    style={{
                                                        opacity: 0.3,
                                                        cursor: "not-allowed",
                                                    }}
                                                />
                                            )}
                                            </View>
                                        ))
                                    )}
                                </FieldContainer>
                            </Collapse>
                        </Box>
                        <Button
                            onClick={toggleDropdown5}
                            variant="outlined"
                            style={{
                                border: "none",
                                marginTop: 15,
                                padding: 0,
                            }}
                        >
                            {isOpen5 ? (
                                <ImageIcon src="/img/arrow_up.svg" alt="" />
                            ) : (
                                <ImageIcon src="/img/arrow_down.svg" alt="" />
                            )}
                            CATEGORIA
                        </Button>
                        <Box
                            style={{
                                paddingBottom: 20,
                                border: "none",
                                borderBottom: "1px solid #E6E6E6",
                                width: "100%",
                                paddingTop: 10,
                                paddingLeft: 10,
                            }}
                        >
                            <Collapse in={isOpen5}>
                                <FieldContainer style={{ width: "60%" }}>
                                    <View
                                        style={{
                                            borderBottom: "1px solid #E6E6E6",
                                            width: "100%",
                                        }}
                                    >
                                        {impersonateCompanyId === null ? (
                                                <ButtonAdd
                                                    onClick={() => {
                                                        setOpenAddCategory(true);
                                                        setSelectedProfile(profile);
                                                    }}
                                                    variant="text"
                                                    style={{
                                                        marginBottom: 15,
                                                        marginRight: 15,
                                                    }}
                                                >
                                                ADICIONAR CATEGORIA
                                            </ButtonAdd>
                                            
                                        ) : (
                                            <ButtonAdd
                                                variant="text"
                                                style={{
                                                    marginBottom: 15,
                                                    marginRight: 15,
                                                    color: "#D3D3D3",
                                                    cursor: "not-allowed",
                                                }}
                                            >
                                                ADICIONAR CATEGORIA
                                            </ButtonAdd>
                                        )}
                                    </View>
                                    {loading ? (
                                        <View
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "20px",
                                            }}
                                        >
                                            <CircularProgress size={24} />
                                        </View>
                                    ) : (
                                    categories.map((categorie) => (
                                        <View
                                            key={categorie.node.id}
                                            style={{
                                                borderBottom:
                                                    "1px solid #E6E6E6",
                                            }}
                                        >
                                            <Item>{categorie.node.name}</Item>
                                            <Item>
                                                {categorie.node
                                                    .restrictedCategory
                                                    ? "RESTRITO"
                                                    : "TODOS"}
                                            </Item>
                                            <Item
                                                style={{
                                                    alignItems: "flex-end",
                                                    marginRight: 25,
                                                }}
                                            >
                                                {categorie?.node?.limitValue
                                                    ? categorie?.node
                                                          ?.limitValue ===
                                                      "0.00"
                                                        ? "SEM LIMITES"
                                                        : helper.floatToMoney(
                                                              categorie?.node
                                                                  ?.limitValue
                                                          )
                                                    : "SEM LIMITES"}
                                            </Item>
                                            <Item
                                                style={{
                                                    alignItems: "flex-end",
                                                    marginRight: 25,
                                                }}
                                            >
                                                {categorie.node.disabled
                                                    ? "INATIVO"
                                                    : "ATIVO"}
                                            </Item>
                                            {impersonateCompanyId === null ? (
                                                <Button
                                                    variant="text"
                                                    startIcon={
                                                        <img
                                                            src="/img/pen_edit.svg"
                                                            alt="Ícone"
                                                        />
                                                    }
                                                    onClick={() => {
                                                        handleOpenEdit(
                                                            setselectedCategory(
                                                                categorie
                                                            )
                                                        );
                                                    }}
                                                />
                                            ) :(
                                                <Button
                                                    variant="text"
                                                    startIcon={
                                                        <img
                                                            src="/img/pen_edit.svg"
                                                            alt="Ícone"
                                                        />
                                                    }
                                                    style={{
                                                        opacity: 0.3,
                                                        cursor: "not-allowed",
                                                    }}
                                                />
                                            )}
                                        </View>
                                        ))
                                    )}
                                </FieldContainer>
                            </Collapse>
                        </Box>
                        <Button
                            onClick={toggleDropdown6}
                            variant="outlined"
                            style={{
                                border: "none",
                                marginTop: 15,
                                padding: 0,
                            }}
                        >
                            {isOpen6 ? (
                                <ImageIcon src="/img/arrow_up.svg" alt="" />
                            ) : (
                                <ImageIcon src="/img/arrow_down.svg" alt="" />
                            )}
                            API
                        </Button>
                        <Box
                            style={{
                                paddingBottom: 20,
                                border: "none",
                                borderBottom: "1px solid #E6E6E6",
                                width: "100%",
                                paddingTop: 10,
                                paddingLeft: 10,
                            }}
                        >
                            <Collapse in={isOpen6}>
                                <Field
                                    style={{
                                        width: "70%",
                                        marginLeft: 30,
                                        flexDirection: "column",
                                    }}
                                >
                                    <TextApi style={{ marginBottom: 30 }}>
                                        Quer obter acesso a todos os recursos
                                        disponíveis em nossa API?{" "}
                                        <strong>É muito simples!</strong>
                                    </TextApi>
                                    <FieldContainer
                                        style={{ marginLeft: 20, width: 888 }}
                                    >
                                        <TextApi>
                                            - Solicite seu acesso encaminhando
                                            uma mensagem através do e-mail
                                            <a
                                                style={{
                                                    color: "#57C4C2",
                                                    fontWeight: 600,
                                                }}
                                                href="mailto:oi@zagapp.com.br"
                                            >
                                                {" "}
                                                oi@zagapp.com.br
                                            </a>
                                            ;
                                        </TextApi>
                                        <TextApi>
                                            - Escreva no assunto{" "}
                                            <strong>“API”</strong>;
                                        </TextApi>
                                        <TextApi>
                                            - Aguarde que nosso time retornará a
                                            mensagem com seu ID e Secret para
                                            autenticação no Portal do
                                            Desenvolvedor que pode ser acessado
                                            neste link:{" "}
                                            <strong
                                                style={{
                                                    color: "#57C4C2",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {" "}
                                                https://zagapp.com.br/dev
                                            </strong>
                                        </TextApi>
                                    </FieldContainer>
                                    <TextApi style={{ marginTop: 30 }}>
                                        <strong>Lembre-se:</strong> Este recurso
                                        está disponível apenas para clientes
                                        pagantes.
                                    </TextApi>
                                </Field>
                            </Collapse>
                        </Box>
                        {/* <FieldContainer>
              <View style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 30, marginBottom: 50 }} >
                <Button style={{ color: "#DE6061" }}>
                  DESATIVAR EMPRESA
                </Button>
                <Button style={{ color: "#181122" }}>
                  EXPORTAR DADOS
                </Button>
              </View>
            </FieldContainer> */}
                    </Grid>
                </Content>
            </Holder>
        </>
    );
}
