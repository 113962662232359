const API_URL = process.env.REACT_APP_API_URL;

export default function Api() {
    const login = async (username, password) => {
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify({
                query: `
          mutation oauthLogin {
            login(
              email: "${username}",
              password: "${password}",
            ) {
              oauthLogin {
                token
                    profile {
                          name
                          email
                          company {
                            id
                            name
                            phone
                            requireProject
                            requireCategory
                            requireReceipt
                            acceptMileage
                            allowCorporate
                            mileageCost
                          }
                          permissions {
                            companySettings
                            users
                            plans
                            credits
                            refundApprove
                            refundPay
                            canImpersonate
                          }
                      }
              }
              oauthLoginError {
                messages
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getProfile = async (token, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          mutation profile {
            profile {
              profile {
                id
                token
                name
                role
                email
                company {
                  id
                  name
                  phone
                  requireProject
                  requireCategory
                  requireReceipt
                  acceptMileage
                  allowCorporate
                  mileageCost
                }
                permissions{
                  companySettings
                  credits
                  plans
                  refundPay
                  refundApprove
                  users
                  canImpersonate
                }
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getImpersonatedCompany = async (token, impersonateCompanyId) => {
      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: token,
    };

      let idParam = "";
          if (impersonateCompanyId !== null) {
            idParam = `(id: ${impersonateCompanyId})`;
          }

      if (impersonateCompanyId) {
          headers["Impersonate"] = impersonateCompanyId;
      }
      return fetch(API_URL + "/admin/graphql", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          query: `
            query {
              allCompanies ${idParam} {
                edges {
                  node {
                  id
                  name
                  phone
                  mileageCost
                  allowsCorporateCard
                  mileageRefundsEnabled
                  }
                }
              }
            }
          `,
        }),
      }).then((data) => {
          return data.json();
        }
      );
    };

    const getRefunds = async (
        token,
        year,
        month,
        startDay,
        endDay,
        startMonth,
        endMonth,
        startYear,
        endYear,
        selectedUsers,
        selectedStatus,
        selectedCategories,
        selectedProjects,
        corporate,
        impersonateCompanyId,
        afterCursor,
        pageSize
    ) => {
        let start, end;

        if (startDay !== null && endDay !== null) {
            start = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
            end = new Date(endYear, endMonth - 1, endDay, 23, 59, 59, 999);
        } else {
            start = new Date(year, month - 1, 1, 0, 0, 0, 0);
            end = new Date(year, month, 1, 23, 59, 59, 0);
            end.setDate(end.getDate() - 1);
        }

        start.setMinutes(start.getMinutes() - start.getTimezoneOffset());
        end.setMinutes(end.getMinutes() - end.getTimezoneOffset());

        start = start.toISOString();
        end = end.toISOString();

        let _users = [];
        for (let index = 0; index < selectedUsers.length; index++) {
            let user = selectedUsers[index];
            user = atob(user.id).split(":")[1];
            _users.push(user);
        }
        if (_users.length > 0) {
            _users = _users.join();
        } else {
            _users = "";
        }

        let _status = [];
        for (let index = 0; index < selectedStatus.length; index++) {
            let status = selectedStatus[index];
            status = parseInt(status);
            _status.push(status);
        }
        if (_status.length > 0) {
            _status = _status.join();
        } else {
            _status = "";
        }

        let _categories = [];
        for (let index = 0; index < selectedCategories.length; index++) {
            let category = selectedCategories[index];
            category = atob(category.id).split(":")[1];
            _categories.push(category);
        }
        if (_categories.length > 0) {
            _categories = _categories.join();
        } else {
            _categories = "";
        }

        let _projects = [];
        for (let index = 0; index < selectedProjects.length; index++) {
            let project = selectedProjects[index];
            project = atob(project.id).split(":")[1];
            _projects.push(project);
        }
        if (_projects.length > 0) {
            _projects = _projects.join();
        } else {
            _projects = "";
        }

        let corporateParam = "";
        if (corporate !== null) {
            corporateParam = `, isNotRefundable: ${corporate}`;
        }

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        const afterParam = afterCursor ? `, after: "${afterCursor}"` : "";

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allZagviewRefunds (first: ${pageSize} ${afterParam}, createdAt_Gte: "${start}", createdAt_Lte: "${end}", user_In: [${_users}], status_In: [${_status}], category_In: [${_categories}], project_In: [${_projects}] ${corporateParam}) {
              edges {
                node {
                  id
                  value
                  mileage
                  createdAt
                  isNotRefundable
                  comment
                  status
                  createdAt
                  suspicion
                  paidWithCredit
                  paidAt
                  user {
                    id
                    name
                    email
                    availableCredit
                    role
                  }
                  company {
                    id
                    name
                  }
                  project {
                    id
                    name
                  }
                  category {
                    id
                    name
                  }
                  receiptFiles {
                    original
                    thumb
                  }
                  suspicion
                  suspectedRefundFiles {
                    suspectRefundImage
                    suspectRefundId
                  }
                }
                cursor
              }
              pageInfo { 
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              totalCount
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getRefund = async (token, id, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            refund(id: "${id}") {
              id
              value
              mileage
              createdAt
              isNotRefundable
              comment
              status
              createdAt
              paidWithCredit
              user {
                id
                name
                availableCredit
                role
              }
              project {
                id
                name
              }
              category {
                id
                name
              }
              receiptFiles {
                original
                thumb
              }
              suspicion
              refundLogs {
                logs {
                  status
                  reason
                  when
                  who
                  role
                }
              }
              suspectedRefundFiles {
                suspectRefundImage
                suspectRefundId
              }
            }
          }   
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getCategories = async (token, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allCategories {
              edges {
                node {
                  id
                  name
                  disabled
                  limitValue
                  restrictedCategory
                }
              }
            }
          }        
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getProjects = async (token, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allProjects {
              edges {
                node {
                  id
                  name
                  disabled
                  restrictedProject
                }
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getBanks = async (token) => {
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: token,
            },
            body: JSON.stringify({
                query: `
          query {
            allBanks {
              edges {
                node {
                  id
                  code
                  bank
                }
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getUserEdit = async (token, id, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            userWithCompany(id: "${id}") {
              id
              name
              email
              role
              userHiringType
              cpf
              cnpj
              bankCode
              bankAgency
              bankAccount
              pixType
              pix
              restrictedCategories {
                id
                name
              }
              restrictedProjects {
                id
                name
              }
              userPermissions {
                companySettings
                users
                plans
                credits
                refundApprove
                refundPay
                categories
                projects
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getUser = async (token, id, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allZagviewUsers (user: "${id}") {
              edges {
                node {
                  id
                  name
                  availableCredit
                }
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getUsers = async (
        token,
        search,
        endCursor,
        impersonateCompanyId
      ) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        let nameParam = "";
        if (search !== null) {
          nameParam = `, name_Icontains: "${search}"`;
        }

        const afterParam = endCursor ? `, after: "${endCursor}"` : "";

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allZagviewUsers(first: 30 ${afterParam} ${nameParam}) {
              edges {
                node {
                  id
                  name
                  availableCredit
                  disabled
                  restrictedCategories {
                    name 
                    id
                  }
                  restrictedProjects {
                    name 
                    id
                  }
                }
              }
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }  
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getUsersCredit = async (token, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allZagviewUsers {
              edges {
                node {
                  id
                  name
                  availableCredit
                }
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getActiveUsers = async (token, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allZagviewUsers(disabled: false) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getUsersCSV = async (token, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allZagviewUsers {
              edges {
                node {
                  id
                  name
                  email
                  userHiringType
                  cpf
                  cnpj
                  bankCode
                  bankAgency
                  bankAccount
                  pixType
                  pix
                }
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getUsersFiltered = async (
        token,
        disabled,
        role,
        impersonateCompanyId
    ) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        if (disabled === "all") {
            disabled = "";
        }
        if (role === "all") {
            role = "";
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
              query {
                allZagviewUsers (role_In: [${role}], disabled_In: [${disabled}]) {
                  edges {
                    node {
                      id
                      name
                      email
                      disabled
                      role
                      availableCredit
                      deleted
                      company {
                        id
                        name
                      }
                    }
                  }
                }
              }
            `,
                }),
            }).then((data) => {
                return data.json();
            });
        };

    const addUsers = async (
        token,
        selectedUsers,
        selectedCategories,
        selectedProjects,
        impersonateCompanyId
    ) => {
        let _users = [];
        for (let index = 0; index < selectedUsers.length; index++) {
            let user = selectedUsers[index];
            user = `"${btoa(JSON.stringify(user))}"`;
            _users.push(user);
        }
        if (_users.length > 0) {
            _users = _users.join();
        } else {
            _users = "";
        }

        let _categories = [];
        for (let index = 0; index < selectedCategories.length; index++) {
            let category = selectedCategories[index];
            category = atob(category).split(":")[1];
            _categories.push(category);
        }
        if (_categories.length > 0) {
            _categories = _categories.join();
        } else {
            _categories = "";
        }

        let _projects = [];
        for (let index = 0; index < selectedProjects.length; index++) {
            let project = selectedProjects[index];
            project = atob(project).split(":")[1];
            _projects.push(project);
        }
        if (_projects.length > 0) {
            _projects = _projects.join();
        } else {
            _projects = "";
        }

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          mutation addUsers {
            addUsers(users: [${_users}], categories: [${_categories}], projects: [${_projects}]) {
              addUsers {
                messages
              }
              addUsersError {
                messages
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const editUsers = async (
        token,
        selectedUsers,
        selectedCategories,
        selectedProjects,
        impersonateCompanyId
    ) => {
        let _users = [];
        for (let index = 0; index < selectedUsers.length; index++) {
            let user = selectedUsers[index];
            user = `"${btoa(JSON.stringify(user))}"`;
            _users.push(user);
        }
        if (_users.length > 0) {
            _users = _users.join();
        } else {
            _users = "";
        }

        let _categories = [];
        for (let index = 0; index < selectedCategories.length; index++) {
            let category = selectedCategories[index];
            category = atob(category).split(":")[1];
            _categories.push(category);
        }
        if (_categories.length > 0) {
            _categories = _categories.join();
        } else {
            _categories = "";
        }

        let _projects = [];
        for (let index = 0; index < selectedProjects.length; index++) {
            let project = selectedProjects[index];
            project = atob(project).split(":")[1];
            _projects.push(project);
        }
        if (_projects.length > 0) {
            _projects = _projects.join();
        } else {
            _projects = "";
        }

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          mutation editUsersBatch {
            editUsersBatch(users: [${_users}], categories: [${_categories}], projects: [${_projects}]) {
              editUsersBatch {
                messages
              }
              editUsersBatchError {
                messages
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getCredits = async (
        token,
        impersonateCompanyId,
        afterCursor,
        pageSize
    ) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        const afterParam = afterCursor ? `, after: "${afterCursor}"` : "";

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allCreditOperations(first: ${pageSize} ${afterParam}) {
              edges {
                node {
                  id
                  userId
                  userName
                  refundId
                  createdAt
                  value
                }
              }
              pageInfo { 
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              totalCount
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getCreditsFiltered = async (token, user, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            allCreditOperations(userId: ${user}) {
              edges {
                node {
                  id
                  userId
                  userName
                  refundId
                  createdAt
                  value
                }
              }
              pageInfo { 
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
              }
              totalCount
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const sendCredit = async (token, user, credit, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          mutation credit {
            credit(user: "${user}", credit: ${credit}) {
              credit {
                messages
              }
              creditError {
                messages
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const updateUsers = async (token, users, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: token,
            },
            body: JSON.stringify({
                query: `
          mutation editMultipleUsers {
            editMultipleUsers(users: ${JSON.stringify(users)}) {
              editUsers {
                users
              }
              editUsersError {
                messages
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const updateRefunds = async (token, refunds, impersonateCompanyId) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: token,
            },
            body: JSON.stringify({
                query: `
        mutation editMultipleRefunds {
          editMultipleRefunds (refunds: ${refunds}) {
            multipleRefunds {
              refunds
            }
            multipleRefundsError {
              messages
            }
          }
        }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const resetPassword = async (username) => {
        return fetch(API_URL + "/reset/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify({
                query: `
        mutation resetPassword {
          resetPassword(email:"${username}") {
            resetPassword {
              email
            }
          }
        }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const commitResetPassword = async (password, confirmPassword, token) => {
        return fetch(API_URL + "/reset/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify({
                query: `
        mutation resetPasswordCommit {
          resetPasswordCommit (password:"${password}", confirmPassword:"${confirmPassword}", token: "${token}") {
            resetPassword {
              email
            }
            resetPasswordError {
              messages
            }
          }
        }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const saveAdminUser = async (
        token,
        name,
        email,
        role,
        hiringType,
        document,
        bank,
        agency,
        account,
        pixType,
        pixValue,
        companySettings,
        userSettings,
        creditsSettings,
        plansSettings,
        refundPay,
        refundApprove,
        selectedCategories,
        selectedProjects,
        selectedAdminCategories,
        selectedAdminProjects,
        impersonateCompanyId
    ) => {
        const data = JSON.stringify({
            name,
            email,
            role,
            hiringType,
            document,
            bank,
            agency,
            account,
            pixType,
            pixValue,
            companySettings,
            userSettings,
            creditsSettings,
            plansSettings,
            refundPay,
            refundApprove,
            selectedCategories,
            selectedProjects,
            selectedAdminCategories,
            selectedAdminProjects,
        });
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          mutation editUser {
            editUser (data: "${btoa(data)}") {
              editUser {
                messages
              }
              editUserError {
                messages
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const editCompany = async (
        token,
        name,
        phone,
        allowsCorporateCard,
        mileageRefundsEnabled,
        mileageCost,
        company,
        impersonateCompanyId
    ) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
        mutation editCompany {
          editCompany (company: { name: "${name}", phone: "${phone}" allowsCorporateCard: "${allowsCorporateCard}", mileageRefundsEnabled: "${mileageRefundsEnabled}", mileageCost: ${mileageCost}, 
        }, companyId: ${company}) {
            editCompany {
              company
              companyId
            }
            editCompanyError {
              messages
            }
          }	
        }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const editCategoryOrProject = async (
        token,
        dataId,
        dataType,
        users,
        name,
        disabled,
        limitValue,
        addAll,
        removeAll,
        impersonateCompanyId
    ) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        let addAllParam = "";
        if (addAll) {
          addAllParam = `, addAll: ${addAll}`;
        }

        let removeAllParam = "";
        if (removeAll) {
          removeAllParam = `, removeAll: ${removeAll}`;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
                  mutation EditHiddenList {
                    editListHidden(
                      dataId: "${dataId}"
                      dataType: "${dataType}"
                      users: ${users}
                      name: "${name}"
                      disabled: "${disabled}"
                      limitValue : ${limitValue}
                      ${removeAllParam}
                      ${addAllParam}
                    ) {
                      editListHidden {
                        dataId
                      }
                      editListHiddenError {
                        messages
                      }
                    }
                  }
                  `,
              }),
            }).then((data) => {
                return data.json();
            });
        };

    const createCategory = async (
        token,
        name,
        limitValue,
        users,
        company,
        removeAll,
        impersonateCompanyId
    ) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        let removeAllParam = "";
        if (removeAll) {
          removeAllParam = `removeAll: ${removeAll}`;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
        mutation createCategories {
          createCategories(
            categories: [
              {
                name: "${name}"
                limitValue: ${limitValue}
                users: ${users}
                ${removeAllParam}
              }
            ]
            companyId: ${company}
          ) {
            createCategories {
              categories
              companyId
            }
            createCategoriesError{
              messages
            }
          }
        }
        
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const createProject = async (
        token,
        name,
        users,
        companyId,
        removeAll,
        impersonateCompanyId
    ) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        let removeAllParam = "";
        if (removeAll) {
          removeAllParam = `removeAll: ${removeAll}`;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
        mutation createProjects {
          createProjects(
            projects: [
              {
                name: "${name}"
                users: ${users}
                ${removeAllParam}
              }
            ]
            companyId: ${companyId}
          ) {
            createProjects {
              projects
              companyId
            }
            createProjectsError{
              messages
            }
          }
        }
        
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getRefundSummary = async (
        token,
        users,
        year,
        month,
        startDay,
        endDay,
        startMonth,
        endMonth,
        startYear,
        endYear,
        status,
        projects,
        categories,
        corporate,
        warning,
        impersonateCompanyId
    ) => {
        let start, end;

        if (startDay !== null && endDay !== null) {
          start = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
          end = new Date(endYear, endMonth - 1, endDay, 23, 59, 59, 999); 
      } else {
          start = new Date(year, month - 1, 1, 0, 0, 0, 0);
          end = new Date(year, month, 0, 23, 59, 59, 999);
      }
      
      
        start.setMinutes(start.getMinutes() - start.getTimezoneOffset());
        end.setMinutes(end.getMinutes() - end.getTimezoneOffset());

        start = start.toISOString();
        end = end.toISOString();

        let usersParam = "";
        if (users) {
            usersParam = `, users: [${users}]`;
        }

        let statusParams = [];
        if (status) {
            statusParams = `status: [${status}]`;
        }

        let projectsParam = "";
        if (projects) {
            projectsParam = `, projects: [${projects}]`;
        }

        let categoriesParam = "";
        if (categories) {
            categoriesParam = `, categories: [${categories}]`;
        }

        let corporateParam = "";
        if (corporate !== null) {
            corporateParam = `corporate: ${corporate}`;
        }

        let warningParam = "";
        if (warning) {
            warningParam = `warning: ${warning}`;
        }

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          mutation sumValues {
            sumValues(
              ${usersParam}
              createGte: "${start}"
              createLte: "${end}"
              ${statusParams}
              ${projectsParam}
              ${categoriesParam}
              ${corporateParam}
              ${warningParam}
            ) {
              sumValues {
                total
                approved
                pending
                rejected
                paid
                totalCorporateCard
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const sendToQueue = async (
        token,
        task,
        companyId = null,
        name,
        email,
        month = null,
        year = null,
        startDay = null,
        startMonth = null,
        startYear = null,
        endDay = null,
        endMonth = null,
        endYear = null,
        notRefundable = null,
        selectedStatus = [],
        selectedUsers = [],
        selectedProjects = [],
        selectedCategories = [],
        impersonateCompanyId = null,
        refunds_count = 0
    ) => {
        let start, end;

        if (startDay !== null && endDay !== null) {
          start = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
          end = new Date(endYear, endMonth - 1, endDay, 23, 59, 59, 999); 
        } else {
            start = new Date(year, month - 1, 1, 0, 0, 0, 0);
            end = new Date(year, month, 0, 23, 59, 59, 999);
        }

        start.setMinutes(start.getMinutes() - start.getTimezoneOffset());
        end.setMinutes(end.getMinutes() - end.getTimezoneOffset());

        start = start.toISOString();
        end = end.toISOString();

        let _users = [];
        for (let index = 0; index < selectedUsers.length; index++) {
            let user = selectedUsers[index];
            user = parseInt(atob(user.id).split(":")[1]);
            _users.push(user);
        }
        if (_users.length === 0) {
            _users = null;
        }

        let _status = [];
        for (let index = 0; index < selectedStatus.length; index++) {
            let status = selectedStatus[index];
            _status.push(status);
        }
        if (_status.length > 0) {
            _status = _status.join();
        } else {
            _status = null;
        }

        let _categories = [];
        for (let index = 0; index < selectedCategories.length; index++) {
            let category = selectedCategories[index];
            category = parseInt(atob(category.id).split(":")[1]);
            _categories.push(category);
        }
        if (_categories.length === 0) {
          _categories = null;
        }


        let _projects = [];
        for (let index = 0; index < selectedProjects.length; index++) {
            let project = selectedProjects[index];
            project = parseInt(atob(project.id).split(":")[1]);
            _projects.push(project);
        }
        if (_projects.length === 0) {
          _projects = null;
        }

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        if (task === "pdf") {
            const _json = {
                task: task,
                companyId: impersonateCompanyId
                    ? impersonateCompanyId
                    : companyId,
                // name: name,
                // email: email,
                createGte: start,
                createLte: end,
                month: month,
                year: year,
                notRefundable: notRefundable,
                status: selectedStatus,
                users: _users ? _users : null,
                projects: _projects ? _projects : null,
                categories: _categories ? _categories : null,
                refunds_count: refunds_count,
            };
            window.open(`${API_URL}/pdf/${btoa(JSON.stringify(_json))}/`);
            return true;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
           mutation sqsQueue {
            sqsQueue (queue: [
              {
                task: "${task}", 
                ${companyId ? `companyId: ${companyId},` : ""}
                ${name ? `name: "${name}",` : ""}
                ${email ? `email: "${email}",` : ""}
                ${start ? `createGte: "${start}",` : ""}
                ${end ? `createLte: "${end}",` : ""}
                ${month !== null ? `month: ${month},` : ""}
                ${year !== null ? `year: ${year},` : ""}
                ${notRefundable !== null ? `notRefundable: ${notRefundable ? 1 : 0},` : ""}
                ${
                    selectedStatus.length > 0
                        ? `status: [${selectedStatus}],`
                        : ""
                }
                ${_users !== null ? `users: [${_users}],` : ""}
                ${_projects !== null ? `projects: [${_projects}],` : ""}
                ${_categories !== null ? `categories: [${_categories}],` : ""}
              }
            ]) {
              sendToQueue {
                messages
              }
              sendToQueueError {
                messages
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const deletedUsers = async (
        token,
        email,
        company,
        impersonateCompanyId
    ) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
        mutation deletedUser {
          deletedUser(
              user: [
                {email: "${email}"}
              ]
              companyId: ${company}
          ) {
            deletedUser {
              user
              companyId
            }
            deletedUserError {
              messages
            }
          }
        }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const allCompanies = async (token) => {
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: token,
            },
            body: JSON.stringify({
                query: `
        query {
          allZagviewCompaniesWithOwners {
            edges {
              node {
                id
                name
                ownerEmail
                ownerName
                ownerLastLogin
              }
            }
          }
        }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const checkEmail = async (token, email) => {
        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: token,
            },
            body: JSON.stringify({
                query: `
        query checkEmail{
          checkEmail(email: "${email}")
        }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    const getRefundsRaw = async (
        token,
        year,
        month,
        startDay,
        endDay,
        startMonth,
        endMonth,
        startYear,
        endYear,
        selectedUsers,
        selectedStatus,
        selectedCategories,
        selectedProjects,
        corporate,
        impersonateCompanyId,
        companyId
    ) => {
        let start, end;

        if (startDay !== null && endDay !== null) {
            start = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
            end = new Date(endYear, endMonth - 1, endDay, 23, 59, 59, 999);
        } else {
            start = new Date(year, month - 1, 1, 0, 0, 0, 0);
            end = new Date(year, month, 1, 23, 59, 59, 0);
            end.setDate(end.getDate() - 1);
        }

        start.setMinutes(start.getMinutes() - start.getTimezoneOffset());
        end.setMinutes(end.getMinutes() - end.getTimezoneOffset());

        start = start.toISOString();
        end = end.toISOString();

        let _users = [];
        for (let index = 0; index < selectedUsers.length; index++) {
            let user = selectedUsers[index];
            user = atob(user.id).split(":")[1];
            _users.push(user);
        }
        if (_users.length > 0) {
            _users = _users.join();
        } else {
            _users = "";
        }

        let _status = [];
        for (let index = 0; index < selectedStatus.length; index++) {
            let status = selectedStatus[index];
            status = parseInt(status);
            _status.push(status);
        }
        if (_status.length > 0) {
            _status = _status.join();
        } else {
            _status = "";
        }

        let _categories = [];
        for (let index = 0; index < selectedCategories.length; index++) {
            let category = selectedCategories[index];
            category = atob(category.id).split(":")[1];
            _categories.push(category);
        }
        if (_categories.length > 0) {
            _categories = _categories.join();
        } else {
            _categories = "";
        }

        let _projects = [];
        for (let index = 0; index < selectedProjects.length; index++) {
            let project = selectedProjects[index];
            project = atob(project.id).split(":")[1];
            _projects.push(project);
        }
        if (_projects.length > 0) {
            _projects = _projects.join();
        } else {
            _projects = "";
        }

        let corporateParam = "";
        if (corporate !== null) {
            corporateParam = `isNotRefundable: ${corporate}`;
        }

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: token,
        };

        if (impersonateCompanyId) {
            headers["Impersonate"] = impersonateCompanyId;
        }

        return fetch(API_URL + "/admin/graphql", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                query: `
          query {
            refunds(
              companyId: ${companyId}
              startDate: "${start}"
              endDate: "${end}"
              users: [${_users}]
              status: [${_status}]
              projects: [ ${_projects}]
              categories: [${_categories}]
              ${corporateParam}
            ) {
              user
              userId
              email
              id
              value
              mileage
              createdAt
              isNotRefundable
              paidWithCredit
              comment
              paidAt
              status
              category {
                id
                name
              }
              project {
                id
                name
              }
              logs{
                who
                when
                status
                reason
              }
            }
          }
        `,
            }),
        }).then((data) => {
            return data.json();
        });
    };

    return {
        login,
        getProfile,
        getRefunds,
        getRefund,
        getRefundsRaw,
        getRefundSummary,
        getCategories,
        getProjects,
        getBanks,
        getUserEdit,
        getUser,
        getUsers,
        getActiveUsers,
        getUsersCSV,
        getUsersFiltered,
        updateUsers,
        updateRefunds,
        resetPassword,
        commitResetPassword,
        getCredits,
        getCreditsFiltered,
        sendCredit,
        addUsers,
        editUsers,
        saveAdminUser,
        editCompany,
        editCategoryOrProject,
        createCategory,
        createProject,
        sendToQueue,
        deletedUsers,
        allCompanies,
        checkEmail,
        getUsersCredit,
        getImpersonatedCompany,
    };
}
