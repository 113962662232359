import React from "react";
import styled from "styled-components";

const Title = styled.div`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #181122;
  text-transform: uppercase;
  width: 100%;
`;

const Line = styled.div`
  border: none;
  height: 10px;
  border-bottom: 2px solid #57c4c2;
  width: 96px;
`;

export default function BasicTitle({ title, margin }) {
  return (
    <>
      <Title style={{ marginBottom: margin ? margin : 16 }}>
        {title} {title && <Line />}
      </Title>
    </>
  );
}
