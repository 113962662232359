import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Api from "../libs/api";
import Loader from "../components/loader";
import Button from "@mui/material/Button";

import { useParams } from "react-router-dom";

const Holder = styled.div`
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 676px;
`;

const Card = styled.div`
  background: #fff;
  padding: 56px;
  text-align: center;
  width: 100%;
  max-width: 488px;
  border-radius: 8px;
`;

const Logo = styled.img`
  width: 42px;
  margin-bottom: 40px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #181122;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #181122;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

const Clear = styled.div`
  clear: both;
`;

const Field = styled.div`
  width: 100%;
  max-width: 327px;
  margin: 0 auto;
  margin-bottom: 24px;
  position: relative;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #181122;
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
`;

const Input = styled.input`
  border: 1px solid #181122;
  width: 100%;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #181122;
  padding: 16px;

  &.error {
    border: 1px solid #de6061;
  }
`;

const Submit = styled.div`
  width: 100%;
  max-width: 327px;
  margin: 0 auto;
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #de6061;
  width: 100%;
  text-align: left;
`;

export default function ResetPassword() {
  // instances
  const api = Api();
  let navigate = useNavigate();

  // commit
  const { token } = useParams();

  // states
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const [username, setUsername] = useState();
  const [reseted, setReseted] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }

  const startResetPassword = async () => {
    if (!username) {
      setErrorMessage("Preencha seu e-mail");
    } else {
      setLoading(true);
      await api.resetPassword(username);
      setLoading(false);
      setReseted(true);
    }
  };

  function back() {
    navigate("/login");
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
  }, []);

  useEffect(() => {
    console.log(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      startResetPassword();
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Holder style={{ height: height, width: width }}>
        <Card>
          <Logo src="/img/logo.png" />
          <Clear />
          {reseted ? (
            <>
              <br />
              <br />
              <img src="/img/man.png" width="245" alt="Man" />
              <SubTitle>
                <br />
                <br />
                SUA RECUPERAÇÃO DE SENHA FOI SOLICITADA COM SUCESSO.
                <br />
                ACOMPANHE SEU E-MAIL E SIGA AS INSTRUÇÕES.
              </SubTitle>
              <br />
              <br />
              <Button
                style={{
                  width: "100%",
                  maxWidth: 327,
                  fontWeight: 700,
                  fontSize: 12,
                  padding: "12px 0px",
                  marginTop: 12,
                  color: "#000",
                }}
                variant="contained"
                disableelevation="true"
                color="primary"
                onClick={back}
              >
                VOLTAR AO LOGIN
              </Button>
            </>
          ) : (
            <>
              <Title>Recuperação de senha</Title>
              <SubTitle>
                XII! PARECE QUE VOCÊ PERDEU SUA SENHA. <br />
                MAS CALMA, VAMOS AJUDAR VOCÊ A RECUPERÁ-LA!
              </SubTitle>
              <Field>
                <Label>E-MAIL</Label>
                <Input
                  type="text"
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                  autoFocus
                  onKeyDown={handleKeyDown}
                />
              </Field>
              {errorMessage && (
                <Field>
                  <Error>{errorMessage}</Error>
                </Field>
              )}
              <Submit>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: 327,
                    fontWeight: 700,
                    fontSize: 12,
                    padding: "12px 0px",
                  }}
                  variant="contained"
                  disableelevation="true"
                  color="primary"
                  onClick={startResetPassword}
                >
                  RECUPERAR SENHA
                </Button>
                <Button
                  style={{
                    width: "100%",
                    maxWidth: 327,
                    fontWeight: 700,
                    fontSize: 12,
                    padding: "12px 0px",
                    marginTop: 12,
                    color: "#000",
                  }}
                  color="secondary"
                  onClick={back}
                >
                  VOLTAR AO LOGIN
                </Button>
              </Submit>
            </>
          )}
        </Card>
      </Holder>
    </>
  );
}
