import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import BasicTitle from "../components/title";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Collapse, IconButton } from "@mui/material";
import { useImpersonation } from "../global/impersonation";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";


import Api from "../libs/api";
import Storage from "../libs/storage";
import Helper from "../libs/helper";

import Loader from "./loader";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const ModalCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    border: 1px solid rgb(144, 144, 144);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalClose = styled.img`
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;

const ModalView = styled.div`
    margin-bottom: 16px;
`;

const ModalFooter = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
`;

const Clear = styled.div`
    clear: both;
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 20px;
`;

const SubTitle = styled.div`
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #181122;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 5px;
`;

const ImageIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
    color: #57c4c2;
`;

const View = styled.div`
    display: flex;
    flex-direction: row;
`;

const Title = styled.h3`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
`;


const Item = styled.div`
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    width: 25%;
    margin-top: 15px;
    margin-bottom: 15px;
`;

const ErrorMessage = styled.p`
    color: red;
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 13px;
`;

const CheckBox = styled.input`
    width: 18px;
    height: 18px;
    margin-right: 15px;
`;

export default function EditEntity({ open, close, categorie, project }) {
    let api = Api();
    let storage = Storage();
    let helper = Helper();
    const { impersonateCompanyId } = useImpersonation();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [updateUsers, setUpdateUsers] = useState([]);
    const [name, setName] = useState("");
    const [disabled, setDisabled] = useState("");
    const [limitValue, setLimitValue] = useState();
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [endCursor, setEndCursor] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [addAll, setAddAll] = useState(false);
    const [removeAll, setRemoveAll] = useState(false);
    const [applySearch, setApplySearch] = useState(false);
    const [search, setSearch] = useState("");

    const getUsers = async (searchParam = search) => {
        if (loadingUsers || !hasNextPage) return;
        setLoadingUsers(true);
    
        try {
            const data = await storage.getItem("data");
    
            const targetType = categorie ? "categories" : "projects";
            const targetId = categorie ? categorie?.node?.id : project?.node?.id;
            const decodedTargetId = targetId ? atob(targetId).split(":")[1] : null;
    
            const response = await api.getUsers(
                data.token,
                searchParam ? searchParam : null,
                endCursor,  
                impersonateCompanyId
            );
    
            let newUsers = response?.data?.allZagviewUsers?.edges
            .map((item) => {
                const { id, name, disabled, restrictedCategories, restrictedProjects } = item.node;
        
                let isRestricted;
        
                if (removeAll) {
                    isRestricted = true;
                } else if (addAll) {
                    isRestricted = false;
                } else {
                    isRestricted = targetType === "categories"
                        ? restrictedCategories.some(category => category?.id === decodedTargetId)
                        : restrictedProjects.some(project => project?.id === decodedTargetId);
                }
        
                return {
                    id,
                    name,
                    is_restricted: isRestricted,
                    disabled,
                };
            })
            .filter(user => !user.disabled);
    
            setUsers((prev) => {
                const allUsers = [...prev, ...newUsers];
            
                const uniqueUsers = allUsers.filter(
                    (user, index, self) => self.findIndex(u => u.id === user.id) === index
                );
            
                return uniqueUsers;
            });
    
            setCheckedState((prev) => {
                const updatedCheckedState = users.map((user) => !user.is_restricted);
                return updatedCheckedState;
            });
    
            setEndCursor(response?.data?.allZagviewUsers?.pageInfo?.endCursor);
            setHasNextPage(response?.data?.allZagviewUsers?.pageInfo?.hasNextPage);
        } finally {
            setLoadingUsers(false);
        }
    };

    const editList = async () => {
        setLoading(true);
        const data = await storage.getItem("data");
        const dataType = categorie ? "category" : "project";
        const dataId = categorie ? categorie?.node?.id : project?.node?.id;
        let _users = [];
        updateUsers.forEach((u) => {
            const usersNewArray = {
                id: u.id,
                is_restricted: u.is_restricted,
            };
            return _users.push(btoa(JSON.stringify(usersNewArray)));
        });
        const value = limitValue?.replace("R$", "").replace(",", ".");
        const newValue = parseFloat(value).toFixed(2);
        try {
            const response = await api.editCategoryOrProject(
                data.token,
                dataId,
                dataType,
                JSON.stringify(_users),
                name,
                disabled,
                categorie ? newValue : 0,
                addAll,
                removeAll
            );

            const errorMessage =
                response?.data?.editListHidden?.editListHiddenError
                    ?.messages[0];

            if (errorMessage === "Project name already exists") {
                setErrorMessage("Já existe um centro de custo com esse nome");
            } else if (errorMessage === "Category name already exists") {
                setErrorMessage("Já existe uma categoria com esse nome");
            } else {
                reset();
                close();
            }
        } catch (error) {
            setErrorMessage("Ocorreu um erro inesperado");
        }
        setLoading(false);
    };

    const toggleDropdown1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleDropdown2 = () => {
        setIsOpen2(!isOpen2);
    };

    const toggleDropdown3 = () => {
        setIsOpen3(!isOpen3);
    };

    const [checkedState, setCheckedState] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    useEffect(() => {
        setCheckedState(users.map((user) => !user.is_restricted));
    }, [users]);

    useEffect(() => {
        const someUserRestricted = users.some(
            (user) => user.is_restricted === true
        );
        setSelectAllChecked(!someUserRestricted);
    }, [users]);

    const handleCheckboxChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    
        const updatedUsers = users.map((user, index) => {
            if (index === position) {
                const updatedUser = { ...user, is_restricted: !user.is_restricted };
    
                setUpdateUsers((prev) => {
                    const existingUser = prev.find((u) => u.id === updatedUser.id);
                    if (existingUser) {
                        return prev.map((u) => (u.id === updatedUser.id ? updatedUser : u));
                    }
                    return [...prev, updatedUser];
                });
    
                return updatedUser;
            }
            return user;
        });
    
        setUsers(updatedUsers);
    }; 

    const selectedAll = () => {
        setAddAll(true);
        setRemoveAll(false);

        setUsers((prev) =>
            prev.map((user) => ({
                ...user,
                is_restricted: false,
            }))
        );

        setCheckedState((prev) => prev.map(() => true));
        setUpdateUsers([]);
    }; 

    const removeAllCheck = () => {
        setAddAll(false);
        setRemoveAll(true);

        setUsers((prev) =>
            prev.map((user) => ({
                ...user,
                is_restricted: true,
            }))
        );

        setCheckedState((prev) => prev.map(() => false));
        setUpdateUsers([]);
    };  

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        
        if (
            scrollTop + clientHeight >= scrollHeight - 10 &&
            !loadingUsers &&
            hasNextPage
        ) {
            getUsers();
        }
    };  

    const handleSearch = async () => {
        setApplySearch(true);
        setUsers([]);
        setEndCursor(null);
        setHasNextPage(true);
        await setSearch(search);
        getUsers();
    };

    const handleClearSearch = async () => {
        setApplySearch(true);
        setSearch("");
        setUsers([]);
        setEndCursor(null);
        setHasNextPage(true);
        
        await getUsers(null); 
        
        setApplySearch(false);
    };

    const handleInputChange = (event) => {
        setSearch(event.target.value);
    };
    
    useEffect(() => {
        if (applySearch) {
            setUsers([]);
            setEndCursor(null);
            setHasNextPage(true);
            setApplySearch(false);
            getUsers();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applySearch]);

    const reset = () => {
        setName(categorie ? categorie?.node?.name : project?.node?.name);
        setDisabled((project || categorie)?.node?.disabled ? "True" : "False");
        if (categorie) {
            setLimitValue(
                categorie?.node?.limitValue
                    ? helper.floatToMoney(categorie?.node?.limitValue)
                    : helper.floatToMoney(0)
            );
        }
        setAddAll(false);
        setRemoveAll(false);
        setCheckedState([]);
        setUsers([]);
        setUpdateUsers([]);
        setSearch(null);
        setEndCursor(null);
        setLoading(false);
    };

    useEffect(() => {
        if (categorie) {
            setLimitValue(
                categorie?.node?.limitValue
                    ? helper.floatToMoney(categorie?.node?.limitValue)
                    : helper.floatToMoney(0)
            );
        }
        setName(categorie ? categorie?.node?.name : project?.node?.name);
        setDisabled((project || categorie)?.node?.disabled ? "True" : "False");
        setErrorMessage(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categorie, project]);

    useEffect(() => {
        if ((categorie || project)) {
            setUsers([]); 
            setEndCursor(null); 
            setHasNextPage(true);
            getUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categorie, project]);

    useEffect(() => {
        if (!open) {
            setUsers([]);
            setCheckedState([]);
            setSelectAllChecked(false);
            setEndCursor(null);
            setHasNextPage(true);
        }
    }, [open]);

    const defaultMaskOptions = {
        prefix: "R$ ",
        suffix: "",
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: "",
        allowDecimal: true,
        decimalSymbol: ",",
        decimalLimit: 2,
        integerLimit: 7,
        allowNegative: false,
        allowLeadingZeroes: false,
    };

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
    });

    return (
        <>
            {loading && <Loader />}
            <Modal
                open={open}
                onClose={() => {
                    close();
                    reset();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalCenter
                    style={{
                        width: 1000,
                        maxWidth: "60%",
                        height: 1280,
                        maxHeight: "90%",
                        overflowY: "auto",
                    }}
                >
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={() => {
                            close();
                            reset();
                        }}
                    />
                    {loading && <Loader inside={true} />}
                    <BasicTitle
                        title={
                            categorie
                                ? categorie?.node?.name
                                : project?.node?.name
                        }
                        margin={32}
                    />
                    <Clear />
                    <ModalView>
                        <div>
                            <Button
                                onClick={toggleDropdown1}
                                variant="outlined"
                                style={{
                                    border: "none",
                                    marginTop: 15,
                                    padding: 0,
                                }}
                            >
                                {isOpen1 ? (
                                    <ImageIcon src="/img/arrow_up.svg" alt="" />
                                ) : (
                                    <ImageIcon
                                        src="/img/arrow_down.svg"
                                        alt=""
                                    />
                                )}
                                DETALHES
                            </Button>
                            <Box
                                style={{
                                    paddingBottom: 10,
                                    border: "none",
                                    borderBottom: "1px solid #E6E6E6",
                                    width: "100%",
                                    paddingTop: 20,
                                    paddingLeft: 40,
                                }}
                            >
                                <Collapse in={isOpen1}>
                                    <FieldContainer>
                                        <SubTitle>NOME</SubTitle>
                                        <TextField
                                            id="outlined-basic"
                                            value={name}
                                            variant="outlined"
                                            style={{
                                                borderRadius: 5,
                                                width: "30%",
                                            }}
                                            onChange={(event) => {
                                                setName(event.target.value);
                                            }}
                                        />
                                    </FieldContainer>
                                    <FieldContainer>
                                        <SubTitle>VISIBILIDADE</SubTitle>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{
                                                width: 180,
                                            }}
                                            value={disabled}
                                            onChange={(event) => {
                                                setDisabled(event.target.value);
                                            }}
                                        >
                                            <MenuItem key={1} value={"False"}>
                                                ATIVO
                                            </MenuItem>
                                            <MenuItem key={2} value={"True"}>
                                                INATIVO
                                            </MenuItem>
                                        </Select>
                                    </FieldContainer>
                                </Collapse>
                            </Box>
                            {categorie ? (
                                <>
                                    <Button
                                        onClick={toggleDropdown2}
                                        variant="outlined"
                                        style={{
                                            border: "none",
                                            marginTop: 15,
                                            padding: 0,
                                        }}
                                    >
                                        {isOpen2 ? (
                                            <ImageIcon
                                                src="/img/arrow_up.svg"
                                                alt=""
                                            />
                                        ) : (
                                            <ImageIcon
                                                src="/img/arrow_down.svg"
                                                alt=""
                                            />
                                        )}
                                        LIMITES
                                    </Button>
                                    <Box
                                        style={{
                                            paddingBottom: 10,
                                            border: "none",
                                            borderBottom: "1px solid #E6E6E6",
                                            width: "100%",
                                            paddingTop: 20,
                                            paddingLeft: 40,
                                        }}
                                    >
                                        <Collapse in={isOpen2}>
                                            <FieldContainer>
                                                <SubTitle>
                                                    VALOR LIMITE
                                                </SubTitle>
                                                <MaskedInput
                                                    mask={currencyMask}
                                                    placeholder="R$ 0.00"
                                                    style={{
                                                        fontSize: 16,
                                                        padding: 14,
                                                        border: "1px solid #C1C4C8",
                                                        borderRadius: 4,
                                                        marginRight: 16,
                                                        width: 200,
                                                        fontWeight: 400,
                                                    }}
                                                    value={limitValue}
                                                    onChange={(event) => {
                                                        let _value =
                                                            event.target.value;
                                                        if (_value) {
                                                            setLimitValue(
                                                                _value
                                                            );
                                                        }
                                                    }}
                                                />
                                            </FieldContainer>
                                        </Collapse>
                                    </Box>
                                </>
                            ) : null}
                            <Button
                                onClick={toggleDropdown3}
                                variant="outlined"
                                style={{
                                    border: "none",
                                    marginTop: 15,
                                    padding: 0,
                                }}
                            >
                                {isOpen3 ? (
                                    <ImageIcon src="/img/arrow_up.svg" alt="" />
                                ) : (
                                    <ImageIcon
                                        src="/img/arrow_down.svg"
                                        alt=""
                                    />
                                )}
                                USUÁRIOS
                            </Button>
                            <Box
                                style={{
                                    paddingBottom: 10,
                                    border: "none",
                                    borderBottom: "1px solid #E6E6E6",
                                    width: "100%",
                                    paddingTop: 20,
                                    paddingLeft: 40,
                                }}
                            >
                                <Collapse in={isOpen3}>
                                    <View>
                                    <Box style={{ marginBottom: 20 }}>
                                            <Title>BUSCAR USUÁRIO</Title>
                                            <TextField
                                                value={search}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                style={{
                                                    width: "282px",
                                                    height: "35px",
                                                    borderRadius: 8,
                                                }}
                                                InputProps={{
                                                    endAdornment: search ? (
                                                        <IconButton
                                                            onClick={handleClearSearch}
                                                            edge="end"
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    ) : null,
                                                }}
                                            />
                                            <Button
                                                style={{
                                                    marginLeft: 16,
                                                    fontSize: 12,
                                                    padding: "10px 10px 10px 10px",
                                                    height: 57,
                                                    borderRadius: 8,
                                                }}
                                                variant="contained"
                                                disableelevation="true"
                                                color="primary"
                                                onClick={handleSearch}
                                            >
                                                <img
                                                    src="/img/search.svg"
                                                    alt="Filtro"
                                                    style={{ width: 25, height: 25 }}
                                                />
                                            </Button>
                                        </Box>
                                    </View>
                                    <View
                                        id="scrollable-view" 
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            maxHeight: 300,
                                            overflowY: "auto",
                                        }}
                                        onScroll={(e) => handleScroll(e)}
                                    >
                                        <View>
                                            <CheckBox
                                                style={{ marginBottom: 15 }}
                                                type="checkbox"
                                                checked={selectAllChecked}
                                                onChange={() => selectedAll()}
                                            />
                                            <Item
                                                style={{
                                                    margin: 0,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                SELECIONAR TUDO
                                            </Item>
                                        </View>
                                        {users.map((user, index) => (
                                            <View key={user?.id} style={{ marginBottom: 15 }}>
                                                <CheckBox
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    value={user.name}
                                                    checked={checkedState[index]}
                                                    onChange={() => handleCheckboxChange(index)}
                                                />
                                                <Item style={{ margin: 0 }}>{user?.name}</Item>
                                            </View>
                                        ))}
                                         {loadingUsers && (
                                            <View
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    padding: "10px",
                                                }}
                                            >
                                                <CircularProgress size={24} />
                                            </View>
                                        )}
                                    </View>
                                    <View>
                                        <Button
                                            onClick={() => {
                                                removeAllCheck();
                                            }}
                                            style={{ color: "#DE6061" }}
                                        >
                                            REMOVER TODOS
                                        </Button>
                                    </View>
                                </Collapse>
                            </Box>
                        </div>
                    </ModalView>
                    <ModalFooter>
                        <div>
                            {errorMessage && (
                                <ErrorMessage>{errorMessage}</ErrorMessage>
                            )}
                            <Button
                                onClick={() =>
                                    editList(name, disabled, limitValue)
                                }
                            >
                                SALVAR ALTERAÇÃO
                            </Button>
                            <Button
                                style={{ color: "#DE6061" }}
                                onClick={() => {
                                    reset();
                                    close();
                                }}
                            >
                                CANCELAR ALTERAÇÃO
                            </Button>
                        </div>
                    </ModalFooter>
                </ModalCenter>
            </Modal>
        </>
    );
}
