import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999999999999;
`;

const Image = styled.img`
  width: 60px;
  animation: rotation 2.9s infinite linear;
`;

export default function Loader({ inside }) {
  return (
    <Holder
      style={{
        background: inside ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.6)",
        borderRadius: inside ? 8 : 0,
      }}
    >
      <Image src={`/img/loader.gif`} />
    </Holder>
  );
}
