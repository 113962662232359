import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  padding: 40px 56px;
  width: 100%;
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #181122;
  margin-bottom: 28px;
  text-transform: uppercase;
`;

const Line = styled.div`
  border: none;
  border-bottom: 2px solid #57c4c2;
  width: 96px;
`;

const Page = styled.div``;

export default function Content({ title, children, padding }) {
  return (
    <>
      <Holder
        style={{
          paddingBottom: padding ? padding : 40,
          paddingTop: padding ? padding : 40,
        }}
      >
        {title && (
          <Title>
            {title} <Line />
          </Title>
        )}
        <Page>{children}</Page>
      </Holder>
    </>
  );
}
