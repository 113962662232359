import React, { useState } from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import Storage from "../libs/storage";

import { useImpersonation } from '../global/impersonation';


const Holder = styled.div`
  background: #ffffff;
  height: 100%;
  position: relative;
  padding: 64px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  transition: 0.4s;
`;

const Items = styled.div`
  width: 100%;
`;

const Logout = styled.div`
  width: 100%;
  margin-top: 58px;
  margin-left: 16px;
  justify-content: center;
`;

const LogoutImage = styled.img`
  margin-right: 10px;
`;

const LogoutLink = styled.a`
  display: flex;
  align-items: center;
  color: #de6061;
  cursor: pointer;
`;

const Logo = styled.div`
  margin-bottom: 24px;
`;

const Name = styled.div`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #181122;
  margin-bottom: 8px;
  opacity: 0;
  transition: 0.4s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Company = styled.div`
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #181122;
  margin-bottom: 16px;
  opacity: 0;
  transition: 0.4s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// const ChangeAccount = styled.div`
//   font-family: "Montserrat";
//   font-weight: 600;
//   font-size: 10px;
//   line-height: 14px;
//   text-transform: uppercase;
//   color: #57c4c2;
// `;

const MenuItems = styled.div`
  margin-top: 56px;
`;

const MenuItem = styled.a`
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #181122;
  cursor: pointer;
  padding: 16px 8px;
  border-bottom: 1px solid #e6e6e6;

  &.noborder {
    border-bottom: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const MenuImage = styled.img`
  margin-right: 16px;
`;

const Close = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 71px;
  right: 20px;
  cursor: pointer;
`;

const Span = styled.div`
  transition: 0.4s;
  display: block;
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function Menu({ width, setOpen, showClose, profile, floater, companyName }) {
  const storage = Storage();
  let navigate = useNavigate();    
  const { stopImpersonationLogout } = useImpersonation();
  
  const [hover, setHover] = useState(false);

  const logout = async () => {
    await storage.removeItem("data");
    if (showClose) {
      setOpen(false);
    }
    navigate("/login");
  };

  const close = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <>
      <Holder
        style={{ width: floater ? (hover ? 408 : 110) : "100%" }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {showClose && (
          <Close onClick={close}>
            <img src="/img/modal_close.svg" alt="Menu" onClick={close} />
          </Close>
        )}
        <Items>
          <Logo>
            <img src="/img/logo.svg" alt="Zag" />
          </Logo>
          <Name style={{ opacity: hover ? 1 : 0 }}>
            {profile && profile.name.split(" ")[0]}
          </Name>
          <Company style={{ opacity: hover ? 1 : 0 }}>
            {companyName}
          </Company>
          {/* <ChangeAccount>Trocar Conta</ChangeAccount> */}
          <MenuItems>
            {/* <MenuItem
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <MenuImage src="/img/visaogeral.svg" />
              Visão Geral
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                navigate("/refunds", { state: { profile } });
                if (showClose) {
                  setOpen(false);
                }
              }}
            >
              <MenuImage src="/img/despesas.svg" />

              <Span style={{ width: hover ? 400 : 0, fontWeight: 600 }}>
                Despesas
              </Span>
            </MenuItem>
            {profile && profile.permissions.users && (
              <MenuItem
                onClick={() => {
                  navigate("/users");
                  if (showClose) {
                    setOpen(false);
                  }
                }}
              >
                <MenuImage src="/img/usuarios.svg" />

                <Span style={{ width: hover ? 400 : 0, fontWeight: 600 }}>
                  Usuários
                </Span>
              </MenuItem>
            )}
            {/* <MenuItem
              onClick={() => {
                navigate("/reports");
                if (showClose) {
                  setOpen(false);
                }
              }}
            >
              <MenuImage src="/img/relatorios.svg" />
              <Span style={{ width: hover ? 400 : 0, fontWeight: 600 }}>Relatórios</Span>
            </MenuItem> */}
            {profile && profile.permissions.credits && (
              <MenuItem
                onClick={() => {
                  navigate("/credits");
                  if (showClose) {
                    setOpen(false);
                  }
                }}
              >
                <MenuImage src="/img/creditos.svg" />
                <Span style={{ width: hover ? 400 : 0, fontWeight: 600 }}>
                  Créditos
                </Span>
              </MenuItem>
            )}
            {profile && profile.permissions.companySettings && (
              <MenuItem
                onClick={() => {
                  navigate("/settings");
                  if (showClose) {
                    setOpen(false);
                  }
                }}
              >
                <MenuImage src="/img/configuracoes.svg" />
                <Span style={{ width: hover ? 400 : 0, fontWeight: 600 }}>Configurações</Span>
              </MenuItem>
            )}
            {profile && profile.permissions.canImpersonate && (
              <MenuItem
                onClick={() => {
                  navigate("/impersonate");
                  if (showClose) {
                    setOpen(false);
                  }
                }}
              >
                <MenuImage src="/img/repeat.svg" />
                <Span style={{ width: hover ? 400 : 0, fontWeight: 600 }}>Impersonar</Span>
              </MenuItem>
            )}
            {/* <MenuItem
              onClick={() => {
                navigate("/help");
                if (showClose) {
                  setOpen(false);
                }
              }}
            >
              <MenuImage src="/img/ajuda.svg" />
              Ajuda
            </MenuItem> */}
            {/* {profile && profile.permissions.plans && (
              <MenuItem
                onClick={() => {
                  navigate("/plans");
                  if (showClose) {
                    setOpen(false);
                  }
                }}
              >
                <MenuImage src="/img/planos.svg" />
                <Span style={{ width: hover ? 400 : 0, fontWeight: 600 }}>Planos</Span>
              </MenuItem>
            )} */}
            <MenuItem
              onClick={() => {
                window.open("https://app.zagapp.com.br", "_blank");
                if (showClose) {
                  setOpen(false);
                }
              }}
            >
              <MenuImage src="/img/new_refund.svg" />
              <Span style={{ width: hover ? 400 : 0, fontWeight: 600 }}>
                lançar despesa
              </Span>
            </MenuItem>
          </MenuItems>
        </Items>
        <Logout>
          <LogoutLink onClick={async () => {
            stopImpersonationLogout();
            logout();
          }}>
            <LogoutImage src="/img/logout.svg" />
            <Span style={{ width: hover ? 400 : 0, fontWeight: 600 }}>
              Logout
            </Span>
          </LogoutLink>
        </Logout>
      </Holder>
    </>
  );
}
