import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

import Menu from "../components/menu";
import Api from "../libs/api";
import Storage from "../libs/storage";
import Content from "../components/content";
import { useImpersonation } from "../global/impersonation";

const Holder = styled.div`
    display: flex;
    height: 100%;
    position: fixed;
    width: 100%;
    left: 0px;
    top: 0px;
    margin-top: ${({ hasImpersonationTitle }) =>
        hasImpersonationTitle ? "0" : "0"};
`;

const MenuHolder = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: 0.4s;
`;

const MenuHolderMobile = styled.div`
    position: fixed;
    height: 100%;
    max-width: 100%;
    overflow: scroll;
    left: 0px;
    top: 0px;
    z-index: 99;
    width: 400px;
    filter: drop-shadow(0 0 0.75rem #ddd);
    transition: 0.4s;
`;

const ScreenHolder = styled.div`
    position: relative;
    height: 100%;
    overflow: scroll;
    transition: 0.4s;
`;

const MobileMenu = styled.div`
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 56px 0px 0px;
`;

const MenuHamburgerHolder = styled.div`
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

const Logo = styled.img`
    width: 42px;
`;

export default function Wrapper({ screen }) {
    let api = Api();
    let storage = Storage();
    let navigate = useNavigate();
    let location = useLocation();
    const { isImpersonate } = useImpersonation();

    // states
    const [width, setWidth] = useState(window.innerWidth);
    const [companyName, setCompanyName] = useState();
    const [profile, setProfile] = useState();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const getProfile = async () => {
        const data = await storage.getItem("data");
        if (!data) {
            navigate("/login");
            return;
        }
    
        let response = await api.getProfile(data.token);
        if (response.error || response.data.profile.profile.role === 0) {
            navigate("/login");
            return;
        }
    
        let userProfile = response.data.profile.profile;
        
        userProfile["permissions"] = userProfile.permissions || {}; 
    
        setCompanyName(userProfile?.company?.name);
        setProfile(userProfile);
    
        await storage.saveItem("data", {
            ...data, 
            profile: { ...userProfile }
        });

    };
    
    useEffect(() => {
        getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        getProfile();
        window.addEventListener("resize", handleWindowSizeChange);
        window.addEventListener("load", handleWindowSizeChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Holder hasImpersonationTitle={isImpersonate}>
                <MenuHolder
                    style={{ width: width >= 1280 ? (hover ? 408 : 110) : 0 }}
                    onMouseEnter={() => {
                        setHover(true);
                    }}
                    onMouseLeave={() => {
                        setHover(false);
                    }}
                >
                    <Menu
                        floater={true}
                        showClose={false}
                        profile={profile}
                        companyName={companyName}
                    />
                </MenuHolder>
                <ScreenHolder
                    style={{
                        width:
                            width >= 1280 ? width - (hover ? 408 : 110) : width,
                    }}
                >
                    {width < 1280 && (
                        <Content padding={32}>
                            <MobileMenu>
                                <MenuHolderMobile
                                    style={{ left: open ? 0 : -400 }}
                                >
                                    <Menu
                                        showClose={true}
                                        setOpen={setOpen}
                                        profile={profile}
                                    />
                                </MenuHolderMobile>
                                <MenuHamburgerHolder>
                                    <img
                                        src="/img/hamburger.svg"
                                        alt="Menu"
                                        onClick={() => {
                                            setOpen(!open);
                                        }}
                                    />
                                </MenuHamburgerHolder>
                                <Logo src="/img/logo.png" />
                                <MenuHamburgerHolder />
                            </MobileMenu>
                        </Content>
                    )}
                    {React.cloneElement(screen, { refreshProfile: getProfile })}
                </ScreenHolder>
            </Holder>
        </>
    );
}
