import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Content from "../components/content";
import Loader from "../components/loader";

import Helper from "../libs/helper";
import { useImpersonation } from "../global/impersonation";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import BasicTitle from "../components/title";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid";

import Api from "../libs/api";
import Storage from "../libs/storage";

const Holder = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
`;

const Filters = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
`;

const AddCredit = styled.div`
    width: 100%;
    display: flex;
    align-items: left;
    /* justify-content: space-between; */
    margin-bottom: 32px;
`;

const LeftFilters = styled.div`
    min-width: 100px;
    display: flex;
    align-items: center;
`;

const Data = styled.div`
    margin-bottom: 40px;
`;

export default function Credits() {
    let api = Api();
    let storage = Storage();
    let helper = Helper();
    const { impersonateCompanyId } = useImpersonation();

    const [usersRows, setUsersRows] = useState([
        {
            id: 0,
            name: "---",
            credit: "---",
        },
    ]);

    // const [operationsRows, setOperationsRows] = useState([
    //   {
    //     id: 0,
    //     name: "---",
    //     refund: "---",
    //     created: "---",
    //     credit: "---",
    //   },
    // ]);

    const [loading, setLoading] = useState(false);

    const columnsUsers = [
        { field: "id", headerName: "ID", width: 100 },
        { field: "name", headerName: "Nome", width: 300 },
        { field: "credit", headerName: "Créditos", width: 300 },
    ];

    const RenderCredit = ({ value, refund }) => {
        const isRefund = refund && refund.startsWith("#");

        return (
            <strong
                style={{
                    color: value < 0 || isRefund ? "#DE6061" : "#57C4C2",
                }}
            >
                {isRefund
                    ? `-${helper.floatToMoney(Math.abs(value))}`
                    : helper.floatToMoney(value)}
            </strong>
        );
    };

    const RenderTextRefund = ({ value }) => {
        if (value === "CRÉDITO ADICIONADO" || value === "CRÉDITO DEVOLVIDO") {
            return (
                <strong
                    style={{
                        color:
                            value === "CRÉDITO ADICIONADO"
                                ? "#57C4C2"
                                : "#DE6061",
                        fontSize: 12,
                    }}
                >
                    {value}
                </strong>
            );
        }

        return <span style={{ fontSize: 13 }}>{value}</span>;
    };

    const columnsOperations = [
        { field: "id", headerName: "ID", width: 100 },
        { field: "name", headerName: "Nome", width: 150 },
        {
            field: "refund",
            headerName: "Reembolso",
            width: 200,
            renderCell: (params) => <RenderTextRefund value={params.value} />,
        },
        { field: "created", headerName: "Data", width: 130 },
        {
            field: "credit",
            headerName: "Transação",
            width: 100,
            renderCell: (params) => (
                <RenderCredit value={params.value} refund={params.row.refund} />
            ),
        },
    ];

    const [users, setUsers] = useState([]);
    // const [operations, setOperations] = useState([]);
    const [totalCredits, setTotalCredits] = useState("R$ 0,00");
    const [user, setUser] = useState("all");
    const [loadingCredits, setLoadingCredits] = useState();

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 15,
        hasNextPage: true,
    });

    const getOperations = async (_user) => {
        const data = await storage.getItem("data");
        if (!data) return;
        let response = null;
        const afterCursor = pageState.page > 1 ? pageState.endCursor : null;

        setLoadingCredits(true);

        if (_user === "all") {
            response = await api.getCredits(
                data.token,
                impersonateCompanyId,
                afterCursor,
                pageState.pageSize
            );
        } else {
            response = await api.getCreditsFiltered(
                data.token,
                parseInt(atob(_user).split(":")[1]),
                impersonateCompanyId
            );
        }

        response = response?.data?.allCreditOperations;
        let _rows = [];
        for (let index = 0; index < response?.edges?.length; index++) {
            const operation = response.edges[index].node;

            _rows.push({
                id: parseInt(atob(operation.id).split(":")[1]),
                name: operation.userName,
                refund: operation.refundId
                    ? `#${operation.refundId}`
                    : operation.value > 0
                    ? "CRÉDITO ADICIONADO"
                    : "CRÉDITO DEVOLVIDO",
                created: helper.dateFromString(operation.createdAt),
                credit: parseFloat(operation.value),
            });
        }
        setLoadingCredits(false);
        setPageState((old) => ({
            ...old,
            isLoading: false,
            data: _rows,
            total: response?.totalCount,
            endCursor: response?.pageInfo?.endCursor,
            hasNextPage: response?.pageInfo?.hasNextPage,
        }));
    };

    const getUsers = async () => {
        setLoading(true);
        const data = await storage.getItem("data");
        if (!data) return;
        let response = await api.getUsersCredit(
            data.token,
            impersonateCompanyId
        );
        response = response.data.allZagviewUsers.edges;
        // setUsers(response);
        let _rows = [];
        let _users = [];
        let _totalCredits = 0;
        for (let index = 0; index < response.length; index++) {
            const user = response[index].node;
            _users.push({
                id: user.id,
                name: user.name,
                credit: helper.floatToMoney(user.availableCredit),
            });
            if (parseFloat(user.availableCredit) > 0) {
                _totalCredits =
                    _totalCredits + parseFloat(user.availableCredit);
                const _user = {
                    id: parseInt(atob(user.id).split(":")[1]),
                    name: user.name,
                    credit: helper.floatToMoney(user.availableCredit),
                };
                _rows.push(_user);
            }
        }
        setTotalCredits(helper.floatToMoney(_totalCredits));
        setUsers(_users);
        setUsersRows(_rows);
        setLoading(false);
    };

    useEffect(() => {
        getOperations(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    
    useEffect(() => {
        if (user === "all") {
            getOperations(user);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.page, pageState.pageSize]);
    
    useEffect(() => {
        getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    function toolbarDatagridOptions() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        window.addEventListener("load", handleWindowSizeChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const customFooter = () => {
        return (
            <Box sx={{ p: 1, display: "flex" }}>
                <div
                    style={{ textAlign: "right", width: "100%", fontSize: 20 }}
                >
                    Total:&nbsp;<strong>{totalCredits}</strong>
                </div>
            </Box>
        );
    };

    const defaultMaskOptions = {
        prefix: "R$ ",
        suffix: "",
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: "",
        allowDecimal: true,
        decimalSymbol: ",",
        decimalLimit: 2, // how many digits allowed after the decimal
        integerLimit: 7, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
    };

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
    });

    const [credit, setCredit] = useState(0);

    const sendCredit = async (type) => {
        if (credit !== 0) {
            setLoading(true);
            let _credit = type === "decrease" ? credit * -1 : credit;
            const data = await storage.getItem("data");
            await api.sendCredit(
                data.token,
                parseInt(atob(user).split(":")[1]),
                _credit,
                impersonateCompanyId
            );
            setLoading(false);
            getUsers();
            getOperations(user);
        }
    };

    return (
        <>
            {loading && <Loader />}

            <Holder>
                <Content title={"Créditos"}>
                    <BasicTitle />
                    <Filters
                        style={{ display: width < 1000 ? "block" : "flex" }}
                    >
                        <LeftFilters
                            style={{ marginBottom: width < 1000 ? 16 : 0 }}
                        >
                            <FormControl
                                style={{
                                    width: width < 1000 ? "100%" : 450,
                                    marginRight: 16,
                                }}
                                size="small"
                            >
                                <InputLabel>Usuários</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={user}
                                    label="Status"
                                    onChange={(event) => {
                                        setUser(event.target.value);
                                        getOperations(event.target.value);
                                    }}
                                >
                                    <MenuItem value={"all"}>Todos</MenuItem>
                                    {users
                                        .sort((a, b) =>
                                            a.name.localeCompare(b.name)
                                        )
                                        .map((user) => (
                                            <MenuItem
                                                key={user.id}
                                                value={user.id}
                                            >
                                                {user.name} {user.credit}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </LeftFilters>
                    </Filters>
                    {user === "all" && (
                        <Data style={{ marginBottom: 100 }}>
                            <DataGrid
                                rows={usersRows}
                                columns={columnsUsers}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 20 },
                                    },
                                }}
                                // onCellClick={(
                                //   params: GridCellParams,
                                //   event: MuiEvent<React.MouseEvent>
                                // ) => {
                                //   event.defaultMuiPrevented = true;
                                // }}
                                pageSizeOptions={[
                                    4,
                                    4 * 4,
                                    4 * 4 * 4,
                                    4 * 4 * 4 * 4,
                                ]}
                                //   checkboxSelection
                                disableRowSelectionOnClick
                                //   onRowSelectionModelChange={handleChangeCellSelection}
                                slots={{
                                    toolbar: toolbarDatagridOptions,
                                    footer: customFooter,
                                }}
                            />
                        </Data>
                    )}
                    {user !== "all" && (
                        <FormControl
                            style={{
                                width: width < 1000 ? "100%" : 450,
                                marginRight: 16,
                            }}
                            size="small"
                        >
                            <AddCredit>
                                <MaskedInput
                                    mask={currencyMask}
                                    placeholder="R$ 0.00"
                                    style={{
                                        fontSize: 16,
                                        padding: 14,
                                        border: "1px solid #181122",
                                        borderRadius: 8,
                                        marginRight: 16,
                                        width: 200,
                                        fontWeight: "bold",
                                    }}
                                    onChange={(event) => {
                                        let _value = event.target.value;
                                        if (_value) {
                                            _value = _value.replace("R$ ", "");
                                            _value = parseFloat(
                                                _value.replace(",", ".")
                                            );
                                            setCredit(_value);
                                        }
                                    }}
                                />
                                {impersonateCompanyId === null ? (
                                    <>
                                        <Button
                                        style={{
                                            // width: "100%",
                                            // maxWidth: 327,
                                            fontWeight: 700,
                                            fontSize: 20,
                                            padding: "10px 12px",
                                            marginRight: 16,
                                        }}
                                        variant="contained"
                                        disableelevation="true"
                                        color="error"
                                        onChange={(event) => {
                                            setCredit(event.target.value);
                                        }}
                                        onClick={() => {
                                            sendCredit("decrease");
                                        }}
                                        >
                                            -
                                        </Button>
                                        <Button
                                            style={{
                                                // width: "100%",
                                                // maxWidth: 327,
                                                fontWeight: 700,
                                                fontSize: 20,
                                                padding: "10px 12px",
                                                color: "#ffffff",
                                                marginRight: 16,
                                            }}
                                            variant="contained"
                                            disableelevation="true"
                                            color="primary"
                                            onChange={(event) => {
                                                setCredit(event.target.value);
                                            }}
                                            onClick={() => {
                                                sendCredit("increase");
                                            }}
                                        >
                                            +
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                    <Button
                                        style={{
                                            fontWeight: 700,
                                            fontSize: 20,
                                            padding: "10px 12px",
                                            marginRight: 16,
                                            color: "#d3d3d3",
                                        }}
                                        variant="outlined"
                                        disableelevation="true"
                                        color="primary"
                                    >
                                        -
                                    </Button>
                                    <Button
                                        style={{
                                            fontWeight: 700,
                                            fontSize: 20,
                                            padding: "10px 12px",
                                            marginRight: 16,
                                            color: "#d3d3d3",
                                        }}
                                        variant="outlined"
                                        disableelevation="true"
                                        color="primary"
                                    >
                                        +
                                    </Button>

                                </>
                                )}
                            </AddCredit>
                        </FormControl>
                    )}
                    {loadingCredits && !loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "500px",
                            }}
                        >
                            <CircularProgress size="40px" />
                        </div>
                    ) : pageState.data.length > 0 ? (
                        <>
                            <BasicTitle title={"ÚLTIMAS MOVIMENTAÇÕES"} />
                            <Data style={{ marginBottom: 100 }}>
                                <DataGrid
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: pageState.pageSize,
                                            },
                                        },
                                    }}
                                    autoHeight
                                    rows={pageState.data}
                                    rowCount={pageState.total}
                                    loading={pageState.isLoading}
                                    pageSizeOptions={[15, 25, 50]}
                                    pagination
                                    paginationModel={{
                                        page: pageState.page - 1,
                                        pageSize: pageState.pageSize,
                                    }}
                                    paginationMode="server"
                                    onPaginationModelChange={(model) => {
                                        const { page, pageSize } = model;
                                        setPageState((old) => ({
                                            ...old,
                                            page: page + 1,
                                            pageSize: pageSize,
                                        }));
                                    }}
                                    columns={columnsOperations}
                                    disableRowSelectionOnClick
                                    slots={{
                                        toolbar: toolbarDatagridOptions,
                                    }}
                                    componentsProps={{
                                        row: {
                                            style: { cursor: "pointer" },
                                        },
                                    }}
                                />
                            </Data>
                        </>
                    ) : null}
                </Content>
            </Holder>
        </>
    );
}
