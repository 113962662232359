export default function Helper() {
  const isMobile = () => {
    return window.innerWidth <= 768;
  };

  const screenWidth = () => {
    return window.innerWidth;
  };

  const screenHeight = () => {
    return window.innerHeight;
  };

  const floatToMoney = (value) => {
    value = parseFloat(value);
    return value.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const dateFromString = (date) => {
    const parsedDate = new Date(date);
    
    parsedDate.setHours(parsedDate.getHours() - 3);
    
    const utcDay = parsedDate.getUTCDate().toString().padStart(2, '0');
    const utcMonth = (parsedDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const utcYear = parsedDate.getUTCFullYear();
  
    return `${utcDay}/${utcMonth}/${utcYear}`;
  };
  
  const formatDateTime = (date) => {
    const parsedDate = new Date(date);

    parsedDate.setHours(parsedDate.getHours() - 3);
    
    const utcDay = parsedDate.getUTCDate().toString().padStart(2, '0');
    const utcMonth = (parsedDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const utcYear = parsedDate.getUTCFullYear();
    
    const utcHours = parsedDate.getUTCHours().toString().padStart(2, '0');
    const utcMinutes = parsedDate.getUTCMinutes().toString().padStart(2, '0');
    
    return `${utcDay}/${utcMonth}/${utcYear} - ${utcHours}:${utcMinutes}h`;
  };

  const containsNumbers = (str) => {
    return /\d/.test(str);
  };

  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return phoneNumber;
  }

  return {
    isMobile,
    screenWidth,
    screenHeight,
    floatToMoney,
    dateFromString,
    formatDateTime,
    containsNumbers,
    formatPhoneNumber
  };
}
