import React, { createContext, useContext, useState } from "react";

const ImpersonationContext = createContext();

export const ImpersonationProvider = ({ children }) => {
    const [impersonateCompanyId, setImpersonateCompanyId] = useState(
        localStorage.getItem("impersonateCompanyId") || null
    );

    const startImpersonation = (id) => {
        localStorage.setItem("impersonateCompanyId", id);
        setImpersonateCompanyId(id);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const stopImpersonation = () => {
        localStorage.removeItem("impersonateCompanyId");
        setImpersonateCompanyId(null);
        window.location.reload();
    };

    const stopImpersonationLogout = () => {
        localStorage.removeItem("impersonateCompanyId");
        setImpersonateCompanyId(null);
    };

    const isImpersonate = !!impersonateCompanyId;

    // useEffect(() => {
    //   const handleBeforeUnload = (event) => {
    //     if (isImpersonate) {
    //       stopImpersonation();
    //     }
    //   };

    //   window.addEventListener('beforeunload', handleBeforeUnload);

    //   return () => {
    //     window.removeEventListener('beforeunload', handleBeforeUnload);
    //   };
    // }, [isImpersonate, stopImpersonation]);

    return (
        <ImpersonationContext.Provider
            value={{
                impersonateCompanyId,
                isImpersonate,
                startImpersonation,
                stopImpersonation,
                stopImpersonationLogout,
            }}
        >
            {children}
        </ImpersonationContext.Provider>
    );
};

export const useImpersonation = () => useContext(ImpersonationContext);

export default ImpersonationContext;
